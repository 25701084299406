import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JsonpModule, Jsonp, Response } from '@angular/http';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgsRevealModule} from 'ngx-scrollreveal';
// import { FacebookModule } from 'ngx-facebook';
// import { FileSaverModule  } from 'ngx-filesaver';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { NgxChartsModule } from "@swimlane/ngx-charts";
import { CookieService } from 'ngx-cookie-service';
// import { DataTablesModule } from 'angular-datatables';
import 'hammerjs';


import { environment } from 'environments/environment';
import { AppComponent } from './app.component';

//Module
import { SharedModule } from './shared/shared.module';
import { ChartModule } from './chart/chart.module';
import { AmChartModule } from './am-chart/am-chart.module';

// Translation
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Model
import { DateRange } from 'model/daterange';
import { RouteParams } from 'model/route-params';
import { SvgHelper } from 'model/svg-helper';
import { OrderAnalysis } from 'model/order-analysis';
import { StockCheck } from 'model/stock-check';
import { IssueList } from 'model/issue-list';
import { ReceiveLists } from 'model/receive-lists';
import { Budget } from 'model/budget';
import { Procurement } from 'model/procurement';
import { Vaccine } from 'model/vaccine';
import { Api } from 'model/api';
import { ChartProcessData } from 'model/chart-process-data';
import { PatientVisitClinical } from 'model/patient-visit-clinical';
import { Condemn } from 'model/condemn';
import { Counselling } from 'model/counselling';
import { PrescriptionDispensed } from 'model/prescription-dispensed';
import { SpecialApprovalMedication } from 'model/special-approval-medication';
import { PrescriptionVas } from 'model/vas';
import { Page } from 'model/pages';
import { InterventionFilter } from '../model/intervention-filter';
import { MTACFilter } from '../model/mtac-filter';

// Service 
import { DataService } from 'service/data';
import { LocalSettingsService } from 'service/local-setting';
import { ThemeService } from 'service/theme';
import { HttpHelper } from 'service/http';
import { UserService } from 'service/user';
import { AuthService } from 'service/auth.service';
import { AuthenticatedService } from 'service/authenticated.service';
import { CountryService } from 'service/country';
import { ApiDataService } from 'service/api-data.service';


//Shared Component
import { AlertDialogComponent } from './shared/dialog/alert-dialog/alert-dialog.component';
import { AlertDialogDisableCloseComponent } from './shared/dialog/alert-dialog-disable-close/alert-dialog-disable-close.component';
import { ConfirmDialogComponent } from './shared/dialog/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogWithoutLinkComponent } from './shared/dialog/confirm-dialog-without-link/confirm-dialog-without-link.component';

// Layout
import { HeaderComponent } from './shared/header/header.component';
import { LayoutUserComponent } from './shared/layout-user/layout-user.component';
import { LayoutUserSidebarComponent } from './shared/layout-user-sidebar/layout-user-sidebar.component';
// Special

  
// import { RegisterComponent } from './pages/auth/register/register.component';
// import { ResetComponent } from './pages/auth/reset/reset.component';
// import { ValidateAccComponent } from './pages/auth/validate-acc/validate-acc.component';

// import { VersionCheckComponent } from './pages/version-check/version-check.component';
// import { InterventionComponent } from './pages/intervention/intervention.component';
// import { WardPharmacyComponent } from './pages/ward-pharmacy/ward-pharmacy.component';
// import { MtacComponent } from './pages/mtac/mtac.component';
// import { StockSummaryComponent } from './pages/stock-summary/stock-summary.component';
// import { InventoryModuleComponent } from './pages/inventory-module/inventory-module.component';
//import { VaccineComponent } from './pages/vaccine/vaccine.component';



const routes: Routes = [
	// {
	// 	path: 'auth/verify',
	// 	component: VerifyComponent
	// },
	
	{
		path: 'auth',
		resolve: [AuthenticatedService],
		loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
	},
	
	{
		path: 'dashboard',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
	},
	{
		path: 'stock',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/stock-check/stock-check.module').then(m => m.StockCheckModule)
	}, 
	{
		path: 'issue-lists',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/issue-lists/issue-lists.module').then(m => m.IssueListModule)
	},
	{
		path: 'receive-lists',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/receive-lists/receive-lists.module').then(m => m.ReceiveListModule)
	},
	{
		path: 'budget',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/budget/budget.module').then(m => m.BudgetModule)
	},

	{
		path: 'procurement',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/procurement/procurement.module').then(m => m.ProcurementModule)
	},
	{
		path: 'data-integrity',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/data-integrity/data-integrity.module').then(m => m.DataIntegrityModule)
	}, 
	{
		path: 'version-check',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/version-check/version-check.module').then(m => m.VersionCheckModule)
	}, 
	{
		path: 'patient-visit',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/patient-visit-distribution/patient-visit-distribution.module').then(m => m.PatientVisitDistributionModule)
	},
	{
		path: 'medication-order',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/medication-order/medication-order.module').then(m => m.MedicationOrderModule)
	},
	{
		path: 'condemn',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/condemn/condemn.module').then(m => m.CondemnModule)
	},
	{
		path: 'prescription',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/total-prescription-dispended/total-prescription-dispended.module').then(m => m.PrescriptionDispendedModule)
	},
	{
		path: 'prescription-vas',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/vas/vas.module').then(m => m.VasModule)
	},
	{
		path: 'account',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
	},
	{
		path: 'counselling',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/counselling/counselling.module').then(m => m.CounsellingModule)
	},
	{
		path: 'sam-dashboard',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/special-approval-medication/sam-dashboard/sam-dashboard.module').then(m => m.SamDashboardModule)
	},
	{
		path: 'vaccine-dashboard',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/vaccine/vaccine.module').then(m => m.VaccineModule)
	},
	{
		path: 'sam-application',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/special-approval-medication/sam-application/sam-application.module').then(m => m.SamApplicationModule)
	},
	{
		path: 'sam-top',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/special-approval-medication/sam-top/sam-top.module').then(m => m.SamTopModule)
	},
	{
		path: 'sam-customer-charter',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/special-approval-medication/sam-customer-charter/sam-customer-charter.module').then(m => m.SamCustomerCharterModule)
	},
	{
		path: 'pf-report',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/pf-report/pf-report.module').then(m => m.PfReportModule)
	},
	{
		path: 'queue-lists',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/queue-lists/queue-lists.module').then(m => m.QueueListsModule)
	},
	{
		path: 'inventory-module',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/inventory-module/inventory-module.module').then(m => m.InventoryModule)
	},
	{
		path: 'stock-summary',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/stock-summary/stock-summary.module').then(m => m.StockSummaryModule)
	},
	{
		path: 'intervention',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/intervention/intervention-dashboard/intervention-dashboard.module').then(m => m.InterventionDashboardModule)
	},
	{
		path: 'intervention-outpatient',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/intervention/intervention-outpatient/intervention-outpatient.module').then(m => m.InterventionOutpatientModule)
	},
	{
		path: 'intervention-inpatient',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/intervention/intervention-inpatient/intervention-inpatient.module').then(m => m.InterventionInpatientModule)
	},
	{
		path: 'ward-pharmacy',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/ward-pharmacy/ward-pharmacy.module').then(m => m.WardPharmacyModule)
	},
	{
		path: 'mtac',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/mtac/mtac.module').then(m => m.MtacModule)
	},
	{
		path: 'logistic-dashboard',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/logistic-dashboard/dashboard/dashboard.module').then(m => m.LogisticDashboardModule)
	},
	{
		path: 'quarterly-summary',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/logistic-dashboard/quarterly-summary/quarterly-summary.module').then(m => m.QuarterlySummaryModule)
	},
	{
		path: 'summary-by-item-group',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/logistic-dashboard/summary-by-item-group/summary-by-item-group.module').then(m => m.SummaryByItemGroupModule)
	},
	{
		path: 'medicine-cost-prescription',
		component: LayoutUserComponent,
		resolve: [UserService],
		loadChildren: () => import('./pages/medicine-cost-prescription/medicine-cost-prescription.module').then(m => m.MedicineCostPrescriptionModule)
	},
	// {
	// 	path: 'vaccine',
	// 	component: LayoutUserComponent,
	// 	resolve: [UserService],
	// 	loadChildren: './pages/vaccine/vaccine.module#VaccineModule'
	// },
	{
		path: '',
		component: LayoutUserComponent,
		resolve: [UserService],
		children: [
			{
				path: '**',
				redirectTo: '/dashboard',
				pathMatch: 'full',
			},

		]
	},
	
];


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	let version:number = Math.round(Date.now()/3600000);
	return new TranslateHttpLoader(http, './i18n/', `.json?version=${version}` , );
}


@NgModule({
	declarations: [
		AppComponent,
		

		// Header
		HeaderComponent,
		LayoutUserComponent,
		LayoutUserSidebarComponent,
		// END

		// ValidateAccComponent,
	],
	imports: [
		// FacebookModule.forRoot(),
		BrowserModule,
		NgbModule,//angular bootstrap module
		RouterModule.forRoot(routes, { enableTracing: false, scrollPositionRestoration: 'enabled' }),
		// Angulartics2Module.forRoot(),
		SharedModule,
		ChartModule,
		AmChartModule,
		JsonpModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({ //translate module forRoot at app.module, forChild if at child
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		// NgxChartsModule,
		NgsRevealModule,
		Angulartics2Module.forRoot({
			pageTracking: {
				clearIds: true,
			}
		}),
		// DataTablesModule.forRoot(),
		// FileSaverModule 
	],
	providers: [
		// Angulartics2GoogleAnalytics,
		DateRange,
		RouteParams,
		SvgHelper,
		DataService,
		OrderAnalysis,
		StockCheck,
		IssueList,
		ReceiveLists,
		Budget,
		Procurement,
		Vaccine,
		PatientVisitClinical,
		Condemn,
		Counselling,
		PrescriptionDispensed,
		SpecialApprovalMedication,
		PrescriptionVas,
		Api,
		ChartProcessData,
		Page,
		LocalSettingsService,
		HttpHelper,
		ThemeService,
		UserService,
		AuthService,
		CountryService,
		AuthenticatedService,
		CookieService,
		ApiDataService,
		InterventionFilter,
		MTACFilter
	],
	entryComponents: [
		AlertDialogComponent,
		ConfirmDialogComponent,
		ConfirmDialogWithoutLinkComponent,
		AlertDialogDisableCloseComponent
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
