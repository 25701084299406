import { Injectable, OnDestroy} from "@angular/core";
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'app/shared/dialog/alert-dialog/alert-dialog.component';
import { AlertDialogDisableCloseComponent } from 'app/shared/dialog/alert-dialog-disable-close/alert-dialog-disable-close.component';
import { ConfirmDialogWithoutLinkComponent } from 'app/shared/dialog/confirm-dialog-without-link/confirm-dialog-without-link.component';
import { filter } from 'rxjs/operators';
import { Alert, AlertType } from 'model/alert';

import * as _ from 'lodash';

@Injectable()
export class AlertService implements OnDestroy{
    private subject = new Subject<any>();
    private message = 1;
    private defaultId = 'default-alert';
    // info :any;
    constructor(private dialog:MatDialog){
    }
    show(data:any){
        let dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '500px',
          data: {
            'title':data.title,
            'message': data.message
          },
        });

        console.log("check mat dialog optons",data);

        dialogRef.afterClosed().subscribe(result => {
          // console.log('The dialog was closed', result);
          this.subject.next(result);
        });
        return this.subject;
    }

    showConfirmDialogWithoutLink(data:any){
        let dialogRef = this.dialog.open(ConfirmDialogWithoutLinkComponent, {
          width: '500px',
          data: {
            'title':data.title,
            'message': data.message
          },
        });

        console.log("check mat dialog optons",data);

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          if(data.hasOwnProperty("callback")){
            data.callback(result);
          }
          this.subject.next(result);
        });
        return this.subject,dialogRef;
    }

    showDisableClose(data:any){
        let dialogRef = this.dialog.open(AlertDialogDisableCloseComponent, {
          width: '500px',
          data: {
            'title':data.title,
            'message': data.message
          },
          disableClose : true
        });

        console.log("check mat dialog optons",data);

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          this.subject.next(result);
        });
        return this.subject,dialogRef;
    }


    showAutoCloseDialog(data:any){
        let dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '500px',
          data: {
            'title':data.title,
            'message': data.message
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          // console.log('The dialog was closed', result);
          this.subject.next(result);
        });
        return this.subject, dialogRef;
    }
    ngOnDestroy() {

    }

    // enable subscribing to alerts observable
    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    // convenience methods
    success(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Success, message }));
    }

    error(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Error, message }));
    }

    info(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Info, message }));
    }

    warn(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
    }

    // main alert method    
    alert(alert: Alert) {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear(id = this.defaultId) {
        this.subject.next(new Alert({ id }));
    }
}
