import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { LocaleDatePipe } from 'pipe/localedate.pipe';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { Nl2BrPipeModule } from "nl2br-pipe";

// import { FacebookModule } from 'ngx-facebook';
import { AlertDialogDisableCloseComponent } from './alert-dialog-disable-close/alert-dialog-disable-close.component';
import { ConfirmDialogWithoutLinkComponent } from './confirm-dialog-without-link/confirm-dialog-without-link.component';

@NgModule({
  imports: [
    // FacebookModule,
    CommonModule,
    MatDialogModule,
    TranslateModule,
    Nl2BrPipeModule
  ],
  declarations: [
    AlertDialogComponent,
    LocaleDatePipe,
    ConfirmDialogComponent,
    AlertDialogDisableCloseComponent,
    ConfirmDialogWithoutLinkComponent,
  ],
  exports: [
    CommonModule,
    LocaleDatePipe,
    AlertDialogComponent,
    AlertDialogDisableCloseComponent,
    ConfirmDialogWithoutLinkComponent,
    TranslateModule,
  ]
})
export class DialogModule { }
