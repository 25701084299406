import {Injectable} from "@angular/core";
import { Subject } from 'rxjs/Subject';
import { DataService } from 'service/data';
import { environment } from 'environments/environment';

import * as _ from 'lodash'; 

declare var $:any;

@Injectable()
export class ThemeService{
    private subject = new Subject<any>();
    //todo: better error handling!
    constructor(public data:DataService){   
        this.setTheme(this.getTheme());
    }
    setDefaultTheme(isPremium:boolean){
        var defaultTheme = this.data.get('default-theme');
        if(!defaultTheme){
            if(isPremium){
                defaultTheme = environment.themes.text_dark_theme[0].theme;
            }
            else{
                defaultTheme = environment.themes.text_light_theme[0].theme;
            }
            this.setTheme(defaultTheme);
            
        }
        this.data.set('default-theme', 1);
    }
    getThemes():any{
        return environment.themes;
    }

    getTheme():string{ 
        var selectedTheme = this.data.get('theme');
        if(selectedTheme == 'default'){
            selectedTheme = environment.themes.text_dark_theme[0].theme; //default theme.
        }
        if(!selectedTheme){
            selectedTheme = environment.themes.text_light_theme[0].theme; //default theme.
        } 
        return selectedTheme;
    }

    getThemePath(theme):string{
        return '/assets/css/colors/'+theme+'.css';
    }

    setTheme(theme){
        // console.log(this.data);
        //var d = document.getElementById('theme');
        //d.setAttribute('href', this.getThemePath(theme));
        $('body').removeClass(this.getTheme());
        $('body').addClass(theme);
        
        this.subject.next(theme);
        this.data.set('theme', theme);
    }
    subscribe(callback){
        var subscription = this.subject.subscribe(callback);
        if(this.data){
            callback(this.getTheme());
        }
        return subscription;
    }
}
