import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartService } from 'service/chart';
// import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from 'app/shared/shared.module';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { BarWithMovingBulletsComponent } from './bar-with-moving-bullets/bar-with-moving-bullets.component';
import { DateBasedDataComponent } from './date-based-data/date-based-data.component';
import { TagCloudComponent } from './tag-cloud/tag-cloud.component';
import { PieOfAPieComponent } from './pie-of-a-pie/pie-of-a-pie.component';
import { SemiCirclePieChartComponent } from './semi-circle-pie-chart/semi-circle-pie-chart.component';
import { GanttChartDatesComponent } from './gantt-chart-dates/gantt-chart-dates.component';
import { CollapsibleForceDirectedTreeComponent } from './collapsible-force-directed-tree/collapsible-force-directed-tree.component';
import { GaugeWithTwoAxisComponent } from './gauge-with-two-axis/gauge-with-two-axis.component';
import { ColumnChartWithImagesOnTopComponent } from './column-chart-with-images-on-top/column-chart-with-images-on-top.component';
import { MicroChartAndSparklinesComponent } from './micro-chart-and-sparklines/micro-chart-and-sparklines.component';
import { DraggingPieSlicesComponent } from './dragging-pie-slices/dragging-pie-slices.component';
import { StackedBarChartComponent } from './stacked-bar-chart/stacked-bar-chart.component';
import { LayeredColumnChartComponent } from './layered-column-chart/layered-column-chart.component';
import { LineGraphComponent } from './line-graph/line-graph.component';
import { PieChartWithBrokenDownSlicesComponent } from './pie-chart-with-broken-down-slices/pie-chart-with-broken-down-slices.component';
import { SunburstChartComponent } from './sunburst-chart/sunburst-chart.component';
import { ToggleableChordDiagramComponent } from './toggleable-chord-diagram/toggleable-chord-diagram.component';
import { TraceableSankeyDiagramComponent } from './traceable-sankey-diagram/traceable-sankey-diagram.component';
import { DrilledDownTreemapComponent } from './drilled-down-treemap/drilled-down-treemap.component';
import { DumbbellPlotComponent } from './dumbbell-plot/dumbbell-plot.component';
import { LiveDataComponent } from './live-data/live-data.component';
import { AmChartSelectionCardComponent } from './selection-card/selection-card.component';
import { StackedColumnLineChartComponent } from './stacked-column-line-chart/stacked-column-line-chart.component';
import { ColumnChartSimpleComponent } from './column-chart-simple/column-chart-simple.component';
import { PictorialFractionChartComponent } from './pictorial-fraction-chart/pictorial-fraction-chart.component';
import { HorizontalBarChartComponent } from './horizontal-bar-chart/horizontal-bar-chart.component';
import { HeatmapWithLegendComponent } from './heatmap-with-legend/heatmap-with-legend.component';
import { AmTableComponent } from './am-table/am-table.component';
import { MapComponent } from './map/map.component';
import { SideBySideBarChartComponent } from './side-by-side-bar-chart/side-by-side-bar-chart.component';
import { VariableRadiusPieChartComponent } from './variable-radius-pie-chart/variable-radius-pie-chart.component';
import { ChartTableDisplayComponent } from './chart-table-display/chart-table-display.component';
import { SwitchChartTableComponent } from './switch-chart-table/switch-chart-table.component';
import { ExpandableFunnelComponent } from './expandable-funnel/expandable-funnel.component';
import { DatatableComponent } from './datatable/datatable.component';
import { DynamicChartTableDisplayComponent } from './dynamic-chart-table-display/dynamic-chart-table-display.component';
import { ViewDrillDownChartCardComponent } from './view-drill-down-chart-card/view-drill-down-chart-card.component';
import { HybridDrillDownPieBarChartComponent } from './hybrid-drill-down-pie-bar-chart/hybrid-drill-down-pie-bar-chart.component';
import { DateLineComponent } from './date-line/date-line.component';
import { ChartCustomTableDisplayComponent } from './chart-custom-table-display/chart-custom-table-display.component';

@NgModule({
	declarations: [PieChartComponent, DonutChartComponent, BarWithMovingBulletsComponent, DateBasedDataComponent, TagCloudComponent, PieOfAPieComponent, SemiCirclePieChartComponent, GanttChartDatesComponent, CollapsibleForceDirectedTreeComponent, GaugeWithTwoAxisComponent, ColumnChartWithImagesOnTopComponent, MicroChartAndSparklinesComponent, DraggingPieSlicesComponent, LineGraphComponent, PieChartWithBrokenDownSlicesComponent, SunburstChartComponent, TraceableSankeyDiagramComponent, LayeredColumnChartComponent, StackedBarChartComponent,
		ToggleableChordDiagramComponent,
		DrilledDownTreemapComponent,
		DumbbellPlotComponent,
		LiveDataComponent,
		AmChartSelectionCardComponent,
		StackedColumnLineChartComponent,
		ColumnChartSimpleComponent,
		PictorialFractionChartComponent,
		HorizontalBarChartComponent,
		HeatmapWithLegendComponent,
		AmTableComponent,
		MapComponent,
		SideBySideBarChartComponent,
		VariableRadiusPieChartComponent,
		ChartTableDisplayComponent,
		SwitchChartTableComponent,
		ExpandableFunnelComponent,
		DatatableComponent,
		DynamicChartTableDisplayComponent,
		ViewDrillDownChartCardComponent,
		HybridDrillDownPieBarChartComponent,
		DateLineComponent,
		ChartCustomTableDisplayComponent
	],
	providers:[
		ChartService
	],
	imports: [
		CommonModule,
		SharedModule,
		// DataTablesModule
	],
	exports: [PieChartComponent, DonutChartComponent, BarWithMovingBulletsComponent, DateBasedDataComponent, TagCloudComponent, PieOfAPieComponent, SemiCirclePieChartComponent, GanttChartDatesComponent, CollapsibleForceDirectedTreeComponent, GaugeWithTwoAxisComponent, ColumnChartWithImagesOnTopComponent, MicroChartAndSparklinesComponent, DraggingPieSlicesComponent, LineGraphComponent, PieChartWithBrokenDownSlicesComponent, SunburstChartComponent, TraceableSankeyDiagramComponent, LayeredColumnChartComponent, StackedBarChartComponent,
		ToggleableChordDiagramComponent,DrilledDownTreemapComponent,DumbbellPlotComponent, LiveDataComponent,
		AmChartSelectionCardComponent,StackedColumnLineChartComponent,ColumnChartSimpleComponent,PictorialFractionChartComponent,
		HorizontalBarChartComponent,HeatmapWithLegendComponent,AmTableComponent,MapComponent,SideBySideBarChartComponent,VariableRadiusPieChartComponent,ChartTableDisplayComponent,SwitchChartTableComponent, ExpandableFunnelComponent, DatatableComponent, DynamicChartTableDisplayComponent,
		ViewDrillDownChartCardComponent,DateLineComponent
	],
})
export class AmChartModule { }
