<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper">
  <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <header class="topbar"></header>

    <!-- <left-sidebar></left-sidebar>    -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Start Page Content -->
    <!-- ============================================================== -->
    <share-breadcrumb *ngIf="show"></share-breadcrumb>
    <router-outlet></router-outlet>
    <!-- ============================================================== -->
    <!-- End Start Page Content -->
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
<!-- <script-hack src="/assets/js/custom.js"></script-hack> -->
