import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { Router, Event, NavigationEnd, ActivatedRoute }    from '@angular/router';
import { Http, Response } from '@angular/http';
import { environment } from 'environments/environment';
import { AuthService } from './auth.service';

import * as _ from 'lodash';
@Injectable()
export class UserService implements OnInit, OnDestroy {
	//todo: better error handling!
	// current_url:any;
	constructor(
		private http: Http,
		private router: Router,
		private authService: AuthService,
		) {
		// //this.pageModel.load();
		// this.router.events.subscribe((event:Event) => {
		//     if(event instanceof NavigationEnd ){
		//         console.log('event url',event);
		//         this.current_url = event.url;
		//         // this.ngOnChanges();
		//     }
		// });
	}
	ngOnInit(){
	}
	isLogin() {
		return !this.authService.isTokenExpired();
	}

	resolve(): void {
		if (!this.isLogin()) {
			var query_params = { redirect_url: this.router.url };
			
			if(sessionStorage.getItem('logout') == 'true' || this.isBaseUrl()) {
				query_params['redirect_url'] = null;
				sessionStorage.setItem('logout', 'false');
			}

			this.router.navigate(['/auth/login'], { queryParams: query_params });
			return;
		}
	}

	isBaseUrl() {
		if(this.router.url.length <= 1) {
			return true;
		}

		return false;
	}
	ngOnDestroy() {
		//console.log('Service destroy')
	}
}
