import { Injectable, OnDestroy} from "@angular/core";
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/dialog/confirm-dialog/confirm-dialog.component';
import * as _ from 'lodash'; 

@Injectable()
export class ConfirmService implements OnDestroy{
    private subject = new Subject<any>();
    private message = 1;
    info :any;
    constructor(private dialog:MatDialog){
    }
    show(data:any){
        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '500px',
          data: data,
        });

        dialogRef.afterClosed().subscribe(result => {
          this.subject.next(result);
        });
        return this.subject;
    }
    ngOnDestroy() {
        
    }
}
