import {Injectable} from "@angular/core";
import { Subject } from 'rxjs/Subject';
import { DataService } from 'service/data';
import { environment } from 'environments/environment';

import * as _ from 'lodash'; 

declare var $:any;

@Injectable()
export class CountryService{
    private subject = new Subject<any>();
    //todo: better error handling!
    constructor(public data:DataService){   
        this.setCountry(this.getCountry());
    }
    setDefaultCountry(isPremium:boolean){
        var defaultCountry = this.data.get('my');
        if(!defaultCountry){
            if(isPremium){
                defaultCountry = 'th';
            }
            else{
                defaultCountry = 'my';
            }
            this.setCountry(defaultCountry);
            
        }
        this.data.set('my', 1);
    }
    // getThemes():any{
    //     return environment.themes;
    // }

    getCountry():string{ 
        var selectedCountry = this.data.get('country');
        if(selectedCountry == 'my'){
            selectedCountry = 'th'; //default theme.
        }
        if(!selectedCountry){
            selectedCountry = 'my'; //default theme.
        } 
        return selectedCountry;
    }

    // getThemePath(theme):string{
    //     return '/assets/css/colors/'+theme+'.css';
    // }

    setCountry(country){
        // console.log(this.data);
        //var d = document.getElementById('theme');
        //d.setAttribute('href', this.getThemePath(theme));
        // $('body').removeClass(this.getCountry());
        // $('body').addClass(country);
        
        this.subject.next(country);
        this.data.set('country', country);
    }
    subscribe(callback){
        var subscription = this.subject.subscribe(callback);
        if(this.data){
            callback(this.getCountry());
        }
        return subscription;
    }
}
