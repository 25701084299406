import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import * as jwt_decode from 'jwt-decode';
import { environment } from 'environments/environment';
import { HttpHelper } from 'service/http';
import 'rxjs/add/operator/toPromise';

export const TOKEN_NAME: string = 'jwt_token';

@Injectable()
export class AuthService {

  private url: string = environment.server.apiUrl + '/auth';

  constructor(private http: Http, private httpHelper: HttpHelper,) { }

  getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded.exp === undefined) return null;

    const date = new Date(0); 
    date.setUTCSeconds(decoded.exp);
    //console.log('token expiration:',decoded, date);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if(!token) token = this.getToken();
    if(!token) return true;

    const date = this.getTokenExpirationDate(token);
    if(date === undefined) return false;
    //this.pageModel.load();
    return !(date.valueOf() > new Date().valueOf());
  }

  login(user): Promise<any> {
    return this.http
      .post(`${this.url}/login`, this.httpHelper.buildQuery(user), { headers: this.getHeaders() })
      .toPromise()
      .then(res => {
          this.setToken(res.json().meta.jwt)
      });
  }

  loginWithFacebook(user, callback=null): Promise<any> {
    return this.http
      .post(`${this.url}/social/facebook`, this.httpHelper.buildQuery(user), { headers: this.getHeaders() })
      .toPromise()
      .then(res => {
          this.setToken(res.json().meta.jwt)
          if (callback) {
            callback(res);
          }
      });
  }

  logout(): Promise<any> {
    return this.http
      .post(`${this.url}/logout`, {}, { headers: this.getHeaders() })
      .toPromise()
      .then(res => {
        localStorage.removeItem(TOKEN_NAME);
      });
  }

  getHeaders(): Headers {
    let headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    if (this.getToken()) {
      headers.append('Authorization', `Bearer ${this.getToken()}`);
    }
    return headers;
  }
}
