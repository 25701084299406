import {Injectable, OnDestroy} from "@angular/core";
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { DataService } from 'service/data';
import { environment } from 'environments/environment';

import * as _ from 'lodash'; 

declare var $:any;

@Injectable()
export class ApiDataService implements OnDestroy{
    private subject = new Subject<any>();
    private data:any = {};
    subscriptions: any = [];
    constructor(){   
        this.subject = new Subject();
    }

    setData(data){
      this.data = Object.assign({},data);
      this.subject.next(this.data);
    }

    getData(): Observable<any> {
        return this.subject.asObservable();
    }

    subscribe(callback){
        var subscription = this.subject.subscribe(callback);
        if(!_.isEmpty(this.data)){
          callback(this.data);
        }
        this.subscriptions.push(subscription);
        return subscription;
    }

    dispose(){
        this.subscriptions.forEach(subject =>subject.unsubscribe());
        this.subscriptions = [];
        this.data = {};
    }

    ngOnDestroy() {
        this.subscriptions = [];
    }

}
