import { Injectable, OnDestroy } from "@angular/core";
import { URLSearchParams } from "@angular/http";

@Injectable()
export class HttpHelper implements OnDestroy{

    buildQuery(data) {
        let params = new URLSearchParams();
        for(let key in data){
            params.set(key, data[key])
        }
        return params
    }

    ngOnDestroy() {
        console.log('Service destroy')
    }
}
