import { Injectable, OnDestroy} from "@angular/core";
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
//import * as io from 'socket.io-client';
import * as _ from 'lodash'; 

@Injectable()
export class DataService implements OnDestroy{
    private subject = new Subject<any>();
    private message = 1;
    info :any;
    constructor(){
        this.info = localStorage;
        //console.log(this.info);
    }
    get(key, defaultValue=null){
        if( this.info[key] == null && defaultValue != null){
            return defaultValue;
        }
        return this.info[key];
    }
    set(key, value){
        this.info[key] = value;
    }
    remove(key){
        (this.info).removeItem(key);
    }
    send(message){
        /*this.socket.emit('add-message', message);*/
        setTimeout(() => {
            if(message == ''){

            }
            this.message+=1;
            this.subject.next({text: this.message});
        },2000);
    }
    getConnection(){
        /*
        let observable = new Observable(observer => {
            this.socket = io(this.url);
            this.socket.on('message', (data) => {
                observer.next(data);    
            });
            return () => {
                this.socket.disconnect();
            };  
        })     
        return observable;*/
        return this.subject.asObservable();
    }
    ngOnDestroy() {
        console.log('Service destroy')
    }
}
