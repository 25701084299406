import { Injectable, OnDestroy} from "@angular/core";
import { Http, Response } from '@angular/http';
import { Router }    from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AlertService } from 'service/alert.service';
import { ThemeService } from 'service/theme';
// import { IntegrationService } from 'service/integration.service';
import * as _ from 'lodash'; 

@Injectable()
export class Page implements OnDestroy{
    public privateAccess = false;
    public is_login_checked = false;
    private expired_reminder = false;
    private subject = new Subject<any>();
    private message = 1;
    private subscription = null;
    private loading:boolean = false;
    private dataInDatabase:any;
    private dataInFacebook:any;
    private user_details:any;

    private _data :any = {};

    constructor(
        private alertService:AlertService,
        // private intergrationService:IntegrationService,
        private router:Router,
        private themeService:ThemeService,
        private http: Http
    ){}
    get data():any{
        return this._data;
    }
    set data( data: any){
        this._data = data;
        this.subject.next(this._data);
    }


    checkPermission(){
        var allow = true;
        if(!_.isEmpty(this.data)){
            if( window.location.href.indexOf('/stock') > 0){
                allow = this.data.roles.includes("stock_check");
            }else if( window.location.href.indexOf('/issue-lists') > 0 ){
                allow = this.data.roles.includes("issue");
            }  
        }
        if(!allow){
            var dialog = this.alertService.show({
                  'title': "Permission Denied",
                  'message': "You are not allowed to access this page. Please contact administrator if you think this is a mistake.",
                });
            var subscription = dialog.subscribe((result) => {
                this.router.navigate(['/dashboard']);
                subscription.unsubscribe();
            });
        }
        
    }

    getUser(){
        if(this.loading) return;
        this.loading = true;
        return this.http
          .get(`${environment.server.apiUrl}/accounts/me`, {})
          .toPromise()
          .then(res => {
            
            this.loading = false;
            this.user_details = res.json().response;   
            this.data = this.user_details;
            this.checkPermission();
          })
          .catch(res => {
            var meta = res.json().meta;
            var dialog = this.alertService.show({
              'title': meta.subject,
              'message': meta.message,
            });
            var subscription = dialog.subscribe((result) => {
              // window.location.reload();
                this.loading = false;
                // this.load();
                subscription.unsubscribe();
            });
          });
    }



    subscribe(callback){
        var subscription = this.subject.subscribe(callback);
         if(!_.isEmpty(this._data)){
            callback(this._data);
        }
        else{
            this.getUser();
        }
        
        return subscription;
    }
    ngOnDestroy() {
    }
 
}
