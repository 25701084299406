import { Injectable, OnDestroy } from "@angular/core";
import { Router, Event, NavigationEnd } from '@angular/router';
import { AuthService } from './auth.service';

import * as _ from 'lodash';
@Injectable()
export class AuthenticatedService implements OnDestroy {
    //todo: better error handling!
    url_search:any;

    constructor(
        private router: Router,
        private authService: AuthService) {
    }

    resolve(): void {
        console.log('current_url:', window.location.pathname, this.authService.isTokenExpired());

        if (!this.authService.isTokenExpired()) {
            // this.redirect_url = window.location.href;
            this.url_search = window.location.search;

            // console.log(this.url_search);
            if (this.url_search) {
                var params = '';
                var url = '';
                var plan = null;
                try{
                    if (this.url_search.indexOf('?') >= 0) {
                        // var tempArray = this.
                        url = this.url_search.split('?');

                        // url = tempArray[0];
                        var base_url = decodeURIComponent(url[1].split('=')[1]).split('?');
                        url = base_url[0];
                        if(base_url.length > 1){
                            plan = base_url[1].split('=')[1];
                        }
                        

                    }

                    // console.log(url, plan);
                    this.router.navigate([url], { queryParams: { plan: plan } });
                    return;
                }catch(ex){
                    console.log(ex);
                }
                
                
            }

            if (!window.location.pathname.startsWith('/auth/verify-account')){
                this.router.navigate(['/dashboard']);
            }
            
            // this.router.navigate(['/dashboard']);
            
        };
    }


    ngOnDestroy() {
    }
}
