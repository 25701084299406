import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from 'rxjs/Subject';
import { Api } from 'model/api';
import * as _ from 'lodash';

interface InterventionFilterOptions {
	data: any;
}

@Injectable()
export class InterventionFilter implements OnDestroy {
	private subject = new Subject<any>();
	private _data: InterventionFilterOptions = null;

	constructor(
		private apiModel: Api,
	) { }

	get data(): any {
		return this._data;
	}

	set data(data: any) {
		this._data = data;
		this.subject.next(this._data);
	}

	ngOnDestroy() {
		this._data = null;
	}

	subscribe(callback) {
		var subscription = this.subject.subscribe(callback);
		if (!_.isNull(this._data)) {
			callback(this._data);
		} else {
			this.getApiData();
		}
		return subscription;
	}

	getApiData() {
		var filter_params = {
			'keys': [
				"jkn_code",
				"financial_year_inpatient",
				"financial_year_outpatient",
				"month_from",
				"month_to",
				"facility_type"
			]
		};
		this.apiModel.getFilterOptions('phis/intervention/options', filter_params, this.onLoadFilterOptions.bind(this));
	}

	onLoadFilterOptions(data) {
		this._data = {
			data: data.data
		};
		this.data = this._data;
	}
}