import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert-dialog-disable-close',
  templateUrl: './alert-dialog-disable-close.component.html',
  styleUrls: ['./alert-dialog-disable-close.component.scss']
})
export class AlertDialogDisableCloseComponent{
  constructor(
    public translate:TranslateService,
    public dialogRef: MatDialogRef<AlertDialogDisableCloseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    console.log('data:', data);
  }
  onCloseClick(): void {
    this.dialogRef.close();
  }
}
