import { Injectable } from "@angular/core";
import * as _ from 'lodash';
declare var $: any;

@Injectable()
export class ChartProcessData {

    constructor() {
    }


    setChartData(module, data, default_setting) {
        default_setting[module]['data'] = data[module];
        if (!_.isEmpty(default_setting[module]['sub_field'])) {
            default_setting[module]['data'] = this.processDataWithSubField(default_setting[module]['data'], default_setting, module);
        } else {
            default_setting[module]['data'] = this.processDataWithoutSubField(default_setting[module]['data'], default_setting, module);
        }
        return default_setting[module]['data'];

    }

    processDataWithSubField(data, chart, field_name) {
        if (!_.isEmpty(chart[field_name]["multi_sub_field_key"])) {
            _.map(chart[field_name]["multi_sub_field_key"], (sub_field) => {
                _.map(data, (value, key) => {
                    _.map(value[chart[field_name]["sub_field"]], (data_value, data_key) => {


                        value[data_value.id + "_" + sub_field] = 0;
                        value[data_value.id + "_" + sub_field] = data_value[sub_field];
                    })

                    // value[data_value.id + "_name"] = data_value["name"];


                })
            })
        } else {
            _.map(data, (value, key) => {
                _.map(value[chart[field_name]["sub_field"]], (data_value, data_key) => {
                    if (!_.isEmpty(chart[field_name]["sub_field_key"])) {
                        value[data_value.id] = 0;
                        value[data_value.id] = data_value[chart[field_name]["sub_field_key"]];
                        // value[data_value.id + "_name"] = data_value["name"];

                    } else {
                        value[data_value.id] = 0;
                        value[data_value.id] = [];
                    }
                })
            })
        }




        return data;
    }

    processDataWithoutSubField(data, chart, field_name) {

        _.map(data, (value, key) => {
            // _.map(value[chart[field_name]["sub_field"]], (data_value, data_key) => {
            if (!_.isEmpty(chart[field_name]["sub_field_key"])) {
                value = 0;
                value = value[chart[field_name]["sub_field_key"]];
                // value[data_value.id + "_name"] = data_value["name"];

            } else {
                value = 0;
                value = [];
            }
            // })
        })


        return data;
    }

    setChartOptions(module, data, default_setting) {
        let temp_arr = [];
        let field_keys = [];
        let table_header = [];
        let table_body = [];
        //check has_sub_field
        if (default_setting[module]["sub_field"]) {
            let remove_empty = _.filter(data[module], (item) => {
                if (item.hasOwnProperty(default_setting[module]["sub_field_key"])) {
                    return parseInt(item[default_setting[module]["sub_field_key"]]) !== 0;
                } else {
                    return null;
                }
            })

            var first_item = [];

            if(!_.isEmpty(remove_empty)){
                first_item = _.first(remove_empty);
            }else{
                first_item = _.first(data[module]);
            }

            _.map(first_item[default_setting[module]["sub_field"]], (value, key) => {
                temp_arr.push(
                    {
                        "name": value["name"],
                        "attribute": value["id"],
                        "name_as_legend": true
                    }
                )
                field_keys.push(value["name"]);

                var is_number = false
                var is_float = false
                if (typeof (value[default_setting[module]["sub_field_key"]]) == "number" && Number.isFinite(value[default_setting[module]["sub_field_key"]])) {
                    is_number = true;
                    if (!Number.isInteger(value[default_setting[module]["sub_field_key"]])) {
                        is_float = true;
                    }
                }

                table_header.push(
                    {
                        "id": value["id"],
                        "name": value["name"],
                        "class": "text-right",
                        "is_number": false,
                        "has_several_column": false,
                        "is_sort": true,
                    }
                );

                table_body.push(
                    {
                        "id": value["id"],
                        "name": "",
                        "class": "text-right",
                        "api_field": value["id"],
                        "is_number": is_number,
                        "is_float": is_float,
                        "suffix": "",
                        "prefix": "",
                    }
                );
            })

            let sort_options;

            if (default_setting[module]["chart_options"]["sort_data"]) {

                if (default_setting[module]["chart_options"]["data_type"] == "month") {
                    sort_options = _.orderBy(temp_arr, 'attribute', 'asc');
                } else {
                    sort_options = _.orderBy(temp_arr, 'name', 'asc');
                }

            } else {
                sort_options = temp_arr;
            }



            default_setting[module]["chart_options"]["fields"]["main"] = sort_options;
            default_setting[module]["chart_options"]["keys"] = field_keys;
            if (!default_setting[module]["chart_options"]["custom_table_data"]) {
                if (default_setting[module]["chart_options"]["table_header_order"] == "desc") {
                    table_header = _.orderBy(table_header, "id", "desc");
                    table_body = _.orderBy(table_body, "id", "desc");
                } else if (default_setting[module]["chart_options"]["table_header_order"] == "asc") {
                    table_header = _.orderBy(table_header, "id", "asc");
                    table_body = _.orderBy(table_body, "id", "asc");
                }

                if (first_item.hasOwnProperty("headers")) {
                    let header = _.first(default_setting[module]["chart_options"]["table_header"]);
                    header["id"] = _.split(_.toLower(first_item["headers"]), " ").join("_");
                    header["name"] = first_item["headers"];
                }

                default_setting[module]["chart_options"]["table_header"] = default_setting[module]["chart_options"]["table_header"].concat(table_header);
                default_setting[module]["chart_options"]["table_body"] = default_setting[module]["chart_options"]["table_body"].concat(table_body);

            } else {
                if (first_item.hasOwnProperty("headers")) {
                    let header_list = {};
                    let sub_header = [];
                    let get_keys;

                    header_list = _.groupBy(first_item[default_setting[module]["sub_field"]], "sub_headers");
                    get_keys = _.keys(header_list);
                    _.map(get_keys, (key) => {
                        sub_header.push({
                            "id": "",
                            "name": key,
                            "class": "text-center",
                            "colspan": header_list[key].length,
                            "rowspan": 1,
                            "is_number": false,
                            "has_several_column": false,
                            "is_sort": true
                        });
                    })

                    sub_header.unshift({
                        "id": "",
                        "name": first_item["headers"],
                        "class": "text-left",
                        "colspan": 1,
                        "rowspan": 2,
                        "is_number": false,
                        "has_several_column": false,
                        "is_sort": true
                    })

                    if (first_item.hasOwnProperty("headers")) {
                        default_setting[module]["chart_options"]["sub_table_header"] = [];
                        // header["id"] = _.split(_.toLower(first_item["headers"]), " ").join("_");
                        // header["name"] = "";
                    }
                    default_setting[module]["chart_options"]["table_header"] = sub_header;
                    default_setting[module]["chart_options"]["sub_table_header"] = default_setting[module]["chart_options"]["sub_table_header"].concat(table_header);
                    default_setting[module]["chart_options"]["table_body"] = default_setting[module]["chart_options"]["table_body"].concat(table_body);

                }

            }



        } else {
            // _.map(data[module], (value, key) => {
            //     temp_arr.push(
            //         {
            //             "name": value["name"],
            //             "attribute": value["id"],
            //             "name_as_legend": true
            //         }
            //     )
            //     field_keys.push(value["name"]);
            // })
        }

        return default_setting[module]["chart_options"];


    }





}
