<div class='date-section' [ngClass]="{'float-lg-right':!opts?.nested , 'text-left':opts?.nested}">
    <ul class='date-ul p-0 m-0'>
        <li class="date-li">
            <input class='date-input start-date' [value] = "this.dateInputModel.start| date:'MMM dd, yyyy'" readonly  data-provide="datepicker" [ngClass]="{'border-normal':opts?.nested}">
        </li>
       <li class="date-dash ">
            <i class="fas fa-minus" [ngClass]="{'date-dash-color':!opts?.nested}"></i>
        </li>
        <li class="date-li">
            <input class='date-input end-date' [value] = "this.dateInputModel.end| date:'MMM dd, yyyy'" readonly data-provide="datepicker" [ngClass]="{'border-normal':opts?.nested}">
        </li>
    </ul>
</div>