import { Injectable, OnDestroy} from "@angular/core";
import { Http, Response, ResponseContentType } from '@angular/http';
import { Router,ActivatedRoute }    from '@angular/router';
import { RouteParams } from 'model/route-params';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AlertService } from 'service/alert.service';
import { ThemeService } from 'service/theme';
import { HttpHelper } from 'service/http';
import { DataService } from 'service/data';
import { TOKEN_NAME,AuthService  } from 'service/auth.service';
import * as _ from 'lodash'; 
declare var $:any;
declare var moment: any;

@Injectable()
export class Vaccine implements OnDestroy{
	private subject = new Subject<any>();
	private subscriptions = null;
	private _data :any = {};

    constructor(
        private http: Http,
        private httpHelper: HttpHelper,
        private themeService:ThemeService,
        private authService: AuthService,
        public routeParams: RouteParams,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        ){
    }
    get data():any{
        return this._data;
    }
    set data( data: any){
        var temp = Object.assign({}, data);
        this._data = temp;
        this.subject.next(this._data);
    }

    getLinkData(link,  callback=null) {
        let result;
        $.ajax({
            url: link,
            type: "GET",
            async: false
        })
        .done((data)=> {
            if(callback){
                callback(data);
            }
        }).catch(res => {
            if(callback){
                callback(res.responseJSON);
            }
        });
        // return result;
    }


    setNewToken(token){
        this.authService.setToken(token);
    }




    subscribe(callback){
        var subscription = this.subject.subscribe(callback);
        if(!_.isEmpty(this._data)){
            callback(this._data);
        }

        return subscription;
    }
    ngOnDestroy() {
        _.map(this.subscriptions, (x: any) => { x.unsubscribe(); });
    }
}
