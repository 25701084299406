import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'shared-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['../dialog.common.scss']
})
export class ConfirmDialogComponent {
	constructor(
		public translate:TranslateService,
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
		) {
	}

	ngOnInit() {
	}

	onClick(result): void {
		this.dialogRef.close(result);
	}
}
