<div class="container container-gap pb-0" *ngIf="page_info?.title?.breadcrumb != false">
	<div class="row py-3 pb-md-0 pb-lg-3 pt-lg-4">

		<div class="col-12 col-lg-12 pb-3 pb-lg-0" *ngIf="options.is_enable_header && is_centered_large_title">
			<div class="page-title text-color-highlight-1 text-semi-bold text-size-3 text-center">
				<div class="d-inline-block page-title-label page-title-large-label underline pb-1 mb-3 align-middle text-size-1 title-underline-color dashbroad-color">{{ title }}</div>
			</div>
		</div>

		<div class="col-12 col-lg-7 pb-3 pb-lg-0" *ngIf="options.is_enable_header && !is_centered_large_title">
			<div class="page-title text-color-highlight-1 text-semi-bold text-size-3">
				<div class="d-inline-block page-title-label underline pb-1 align-middle title-underline-color dashbroad-color">{{ title }}</div>
				<ng-container *ngIf='tooltip'>
					<span class=" tooltip-wrapper"><img src='/assets/images/icons/Information_Icon.png' class="pl-2" height='15px' /> <div class="tooltip-message">{{tooltip}}</div></span>
				</ng-container>
				<div class="page_subtitle_label text-right d-inline-block align-middle float-right float-lg-none" *ngIf="is_sub_path && page_data" >
					<span class="d-none d-lg-inline-block mx-2 dash-line translate-center"></span>
					<span class="page-name text-regular align-middle title-color">
						{{ page_data?.name || '@' + page_data?.username }}
					</span>
					<span class="d-inline-block platform-icon platform-{{ page_data?.type }} align-middle">
					</span>
					<!-- <span class="d-none d-lg-inline-block mx-2 dash-line"></span>
					<span class="page-name text-regular align-middle title-color">
						{{ page_data?.name || '@' + page_data?.username }}
					</span>
					 -->
				</div>
			</div>
		</div>

		<div class="col-12 col-lg-5" *ngIf="options.is_enable_date_range">
			<share-daterange></share-daterange>
		</div>
	</div>
</div>