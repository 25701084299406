import { Injectable, OnDestroy} from "@angular/core";
import { Http, Response, ResponseContentType } from '@angular/http';
import { Router }    from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AlertService } from 'service/alert.service';
import { ThemeService } from 'service/theme';
import { HttpHelper } from 'service/http';
import { DataService } from 'service/data';
import { HttpClient, HttpHeaders   } from '@angular/common/http';
import { TOKEN_NAME,AuthService  } from 'service/auth.service';
import * as _ from 'lodash'; 
declare var $:any;
declare var moment: any;

@Injectable()
export class Api implements OnDestroy{
	private subject = new Subject<any>();
	private subscriptions = null;
	private dialog:any = null;
	private _data :any = {};
	
	constructor(
		private http: Http,
		private httpHelper: HttpHelper,
		private themeService:ThemeService,
		private authService: AuthService,
		private alertService: AlertService,
		){
	}
	get data():any{
		return this._data;
	}
	set data( data: any){
		var temp = Object.assign({}, data);
		this._data = temp;
		this.subject.next(this._data);
	}

	getItemDetail(param,callback=null){
		var get_item_detail = {};
        get_item_detail["item_desc"] = param.item_desc;
        get_item_detail["item_category_desc"] = param.item_category_desc;
        get_item_detail["sub_group_desc"] = param.sub_group_desc;
        get_item_detail["drug_nondrug_code"] = param.drug_nondrug_code;
        this.getApiData('phis/stock/search',get_item_detail,callback);
	}

	getApiData(api, api_params,callback=null){
		// api_params["version"] = environment.api_version;
		if(environment.production){
			api_params["version"] = environment.api_version;
		}else{
			api_params["version"] = 6;
		}
		this.http
			.post(`${environment.server.apiUrl}/`+api, api_params)
			.toPromise()
			.then(res => {
				if(callback){
					// var return_json = res.json();
					// return_json.meta.status = 500;
					// return_json.response.data = [];
					// callback(return_json, api_params);
					callback(res.json(), api_params);
				}
				this.data = res.json().response;
			})
			.catch(res => {
				console.log("check res",res);
				var data = {};
				data["data"] = [];
				data["res"] = res;
				// if(callback){
				// 	callback(res.json(), api_params);
				// }
			});
	}

	getFilterOptions(api,api_params,callback=null){
		this.http
			.post(`${environment.server.apiUrl}/`+api, api_params)
			.toPromise()
			.then(res => {
				if(callback){
					callback(res.json().response, api_params);
				}
				this.data = res.json().response;
			})
			.catch(res => {
				console.log('Error:',res);
			});
	}

	getReportData(api, api_params,callback=null){
		// api_filter["test"] = true;
		api_params["version"] = environment.api_version;
		const options = {

	        // Ignore this part or  if you want full response you have 
	        // to explicitly give as 'boby'as http client by default give res.json()
	        observe:'response' as 'body',

	       // have to explicitly give as 'blob' or 'json'
	        responseType: 'text' as 'json' 
	    };
		  let token = localStorage.getItem(TOKEN_NAME);
		this.http
			.post(`${environment.server.apiUrl}/`+api, api_params, {responseType:ResponseContentType.Blob})
			.toPromise()
			.then(res => {
				this.setNewToken(token);
				if(callback){
					console.log(typeof(res));
					callback(res, api_params);
				}
				// this.data = JSON.stringify(res);
			})
			.catch(res => {
				console.log("check res",res);
				var data = {};
				data["data"] = [];
				data["res"] = res;
				if(callback){
					callback(res, api_params);
				}
			});
	}

	convertMonthFromToString(data,month_from,month_to){
		console.log("see month from to convert",month_from);
		_.map(data,(value,key)=>{
			var number_of_months_options = ["January","February","March","April","May","June","July","August","September","October","November","December"];
			console.log("see month from to value",value);
			month_from[1] = Number(month_from[1]);
			month_to[1] = Number(month_to[1]);

			if(value.field_name == "month_from" ){
				value["selected"] = number_of_months_options[month_from[1]-1];
				console.log("see month from to",value["selected"]);
			}
			if(value.field_name == "month_to"){
				value["selected"] = number_of_months_options[month_to[1]-1];
				console.log("see month from to",value["selected"]);

			}
		});
	}


	downloadFile(data,filter,callback){
    	var confirm_dialog = this.alertService.showConfirmDialogWithoutLink({
			'title': data.title,
			'message': data.message,
			"callback" : this.onConfirmDownloadFile.bind(this,filter,callback)
		});
		this.downloadReportApi(filter,callback);
    }

    onConfirmDownloadFile(filter,callback,is_download){
    	console.log("callback confirm",is_download,filter);
    	if(is_download){
    		callback(true);
    		this.dialog = this.alertService.showDisableClose({
				'title': 'Generating Report...',
				'message': 'Your download will start shortly, please wait for 1 - 2 minutes. This dialog will close once the file is downloaded',
			});
			this.downloadReportApi(filter,callback);

    	}
    }

    downloadReportApi(filter,callback){
        var report_filter = {};
        this.getReportData(filter.link,filter,this.onDownloadReportApi.bind(this,callback,filter));
    }

    onDownloadReportApi(callback,filter,data){
    	console.log("check filter download report api",filter,callback,data);
    	// data.status = 500;
    	if(data.status == 200){
	    	var extension = ".xlsx";
	        var file_name = (filter.hasOwnProperty("file_name"))? filter["file_name"]+extension : "Nationwide File Export"+extension;
	        console.log("check file name",file_name);
	        var a = document.createElement('a'),
	             blob = new Blob([data._body], {type: 'application/octet-stream'}),
	             url = window.URL.createObjectURL(blob);
	        a.href = url;
	        a.download = file_name;
	        a.click();
	        window.URL.revokeObjectURL(url);
	        a.remove();
    	}else{ 
    		var ok_dialog = this.alertService.show({
				'title': '',
				'message': 'No data available',
			});
    	}
        
        callback(false);
        this.dialog.close();

    }

    checkSelectedDrugOverLength(item){
    	console.log("check itme length",item.length);
		if(item.length < 11){
			return false;
		}else{
			return true;
		}
	}


	setNewToken(token){
		this.authService.setToken(token);
	}


	subscribe(callback){
		var subscription = this.subject.subscribe(callback);
		if(!_.isEmpty(this._data)){
			callback(this._data);
		}

		return subscription;
	}
	ngOnDestroy() {
		_.map(this.subscriptions, (x: any) => { x.unsubscribe(); });
	}
}
