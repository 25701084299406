import {Injectable} from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'service/data';
import { environment } from 'environments/environment';

import * as _ from 'lodash'; 
@Injectable()
export class LocalSettingsService{
    //todo: better error handling!

    constructor(public translate: TranslateService, public data: DataService){
        // console.log('local setting service constructor', environment.languages);
        translate.setDefaultLang('en');
        this.setLanguage(this.getLanguage());
        //let language = _.map(environment.languages, 'code'); //extract code from environment.languages
        //console.log(language);        
    }
    getCountries():any[]{
        return environment.languages;
    }
    getCountry(code:string){
        return _.find(environment.languages, {'code': code});
    }
    getLanguage():string{
        var selectedLanguage = this.data.get('language');
        if (!selectedLanguage){
            selectedLanguage = this.translate.getBrowserLang();
        }
        return selectedLanguage;
    }
    setLanguage(language: string){
        let available = this.getCountry(language);
        if (available){//the language is in system.
            this.translate.reloadLang(language);
            this.data.set('language', language);
            this.translate.use(language);
        }
        
    }
}