import { Injectable, OnDestroy} from "@angular/core";
// import { Http, Response } from '@angular/http';
import { Meta, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { ActivatedRoute, RoutesRecognized, Router, NavigationEnd } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import * as _ from 'lodash'; 
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class RouteParams implements OnDestroy{
    private subject = new Subject<any>();
    private message = 1;
    private _data :any = {
            id: null,
            platform:null,
            report:null,
        };
    constructor( 
        private meta:Meta,
        private titleService: Title ,
        private location:Location,
        public activatedRoute: ActivatedRoute, 
        private router:Router){
        router.events
        .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
            while (route.firstChild) route = route.firstChild;
            return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
        ).subscribe((event) =>{
            var snapshot = this.activatedRoute.snapshot;
            //this._data.dateEnabled = snapshot.data;
            while(snapshot.firstChild){
                snapshot = snapshot.firstChild;
                if(snapshot){
                    // console.log("route params snapshot: ", snapshot);
                    this._data.id = snapshot.paramMap.get('id');
                    this._data.token = snapshot.paramMap.get('token');
                    this._data.platform = snapshot.paramMap.get('platform');
                    this._data.report = snapshot.paramMap.get('report');
                    this._data.active_category = snapshot.paramMap.get('active_category');
                    this._data.active_country = snapshot.paramMap.get('active_country');
                    this._data.start = snapshot.queryParams.start;
                    this._data.end = snapshot.queryParams.end;
                    this._data.dateEnabled = snapshot.data.dateEnabled;

                    this._data.country_code = snapshot.paramMap.get('country_code');
                    this._data.url = _.chain(snapshot.pathFromRoot).map((x)=>{ return x.url}).flatten().map((x)=>{ return x.path; }).value();
                    // if(snapshot.data.has('dateEnabled')){
                        
                    // }
                    // else{
                    //     this._data.dateEnabled = false;
                    // }
                    //this._data.dateEnabled = snapshot.data.dateEnabled;
                    this._data.title = _.get(snapshot, 'data.title');
                    if(this._data.title && _.get(snapshot, 'data.title.name')){
                        this.setTitle(_.get(snapshot, 'data.title.name'));
                    }
                    //console.log('get:', this.data, snapshot.paramMap);
                    //console.log('get', snapshot);
                    if(this._data.id) break;
                }
            }
            this.data = this.data; //trigger to update all data;
        });
    }
    get data():any{
        return this._data;
    }
    set data( data: any){
        this._data = data;
        this.subject.next(this._data);
    }
    getLocation(){
        return this.location.path();
    }
    setTitle(title){
        // console.log(title);
        this.titleService.setTitle( `${title.name || title} | PhIS+ Dashboard` );
    }
    subscribe(callback){
        var subscription = this.subject.subscribe(callback);
        if(this._data){
            callback(this.data);
        }        
        return subscription;
    }
    addMeta(metatags){
        _.map(metatags, (x:any)=>{
            // console.log('addTag:', x);
            this.meta.updateTag({name: x.name, content: x.content});
        })
    }
    ngOnDestroy() {
        console.log('Service destroy');
    }
}
