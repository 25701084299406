<div class="header-section sticky js-header-section">
	<nav class="navbar navbar-extend-md navbar-light">
		<div class="logo mr-auto mt-md-0">
			<a class='header-menu-icon' routerLink='/dashboard'><img alt="homepage" src='/assets/logo.png' height="54px"
					width="auto" />
			</a>
		</div>
		<div class="navbar-button-group" *ngIf="options?.public != true">
			<a routerLink='/queue-lists' class="toggle header-menu-icon">
				<img alt="queue lists" src='/assets/images/icons/Download_Icon.png' height="30px" width="auto" />
				<span class="navbar-btn-hover text-color-1">Download Queue</span>
			</a>
			<a routerLink='/data-integrity' class="toggle header-menu-icon">
				<img alt="data sync status" src='/assets/images/icons/sync_status.png' height="30px" width="auto" />
				<span class="navbar-btn-hover text-color-1">Data Sync Status</span>
			</a>
			<a routerLink='/dashboard' class="toggle header-menu-icon">
				<img alt="homepage" src='/assets/images/icons/Home_Icon.png' height="30px" width="auto" />
				<span class="navbar-btn-hover text-color-1">Home</span>
			</a>
			<div class="dropdown">
				<button class="btn dropdown-toggle toggle header-menu-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id='dropdownMenuButton'>
					<img alt="homepage" src='/assets/images/icons/Setting_Icon.png' height="30px" width="auto" />
					<span class="navbar-btn-hover text-color-1">Setting</span>
				</button>
				<div class="dropdown-menu dropdown-menu-right border-top-0" aria-labelledby="dropdownMenuButton">
					<button class="dropdown-item" routerLink='/account/setting' type="button">Account</button>
					<button class="dropdown-item" *ngIf="selectedTheme == 'theme-dark'"
						(click)="changeTheme('theme-light')" type="button">Switch Theme</button>
					<button class="dropdown-item" *ngIf="selectedTheme != 'theme-dark'"
						(click)="changeTheme('theme-dark')" type="button">Switch Theme</button>
					<!-- <button class="dropdown-item" (click)="changeTheme('theme-dark')" type="button">changeTheme</button> -->
					<!-- <button class="dropdown-item" routerLink='/account/feedback' type="button">Feedback</button> -->
					<button class="dropdown-item" type="button" (click)="logout()">Logout</button>
				</div>
			</div>


		</div>
	</nav>
</div>