import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';

import { BarChartHorizontalComponent } from './bar-chart-horizontal/bar-chart-horizontal.component';
import { BarChartVerticalComponent } from './bar-chart-vertical/bar-chart-vertical.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { VerticalBarComponent } from './vertical-bar/vertical-bar.component';
// import { WordCloudComponent } from './word-cloud/word-cloud.component';
import { HeatmapChartComponent } from './heatmap-chart/heatmap-chart.component';
import { LineChartMixComponent } from './line-chart-mix/line-chart-mix.component';
import { BarChartHorizontalDateRangeComponent } from './bar-chart-horizontal-date-range/bar-chart-horizontal-date-range.component';
// import { NgxChartsModule } from "@swimlane/ngx-charts";


@NgModule({
	declarations: [
		BarChartHorizontalComponent,
		BarChartVerticalComponent,
		PieChartComponent,
		DonutChartComponent,
		LineChartComponent,
		VerticalBarComponent,
		// WordCloudComponent,
		HeatmapChartComponent,
		LineChartMixComponent,
		BarChartHorizontalDateRangeComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		// NgxChartsModule,
	],
	exports:[
		BarChartHorizontalComponent,
		BarChartVerticalComponent,
		PieChartComponent,
		DonutChartComponent,
		LineChartComponent,
		VerticalBarComponent,
		// WordCloudComponent,
		HeatmapChartComponent,
		LineChartMixComponent,
		BarChartHorizontalDateRangeComponent
	]
})
export class ChartModule { }
