import { Injectable, OnDestroy} from "@angular/core";
import { Http, Response, ResponseContentType } from '@angular/http';
import { Router }    from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AlertService } from 'service/alert.service';
import { ThemeService } from 'service/theme';
import { HttpHelper } from 'service/http';
import { DataService } from 'service/data';
import { HttpClient, HttpHeaders   } from '@angular/common/http';
import { TOKEN_NAME,AuthService  } from 'service/auth.service';
import * as _ from 'lodash'; 
declare var $:any;
declare var moment: any;

@Injectable()
export class PatientVisitClinical implements OnDestroy{
	private subject = new Subject<any>();
	private subscriptions = null;

	private _data :any = {};
	
	constructor(
		private http: Http,
		private httpHelper: HttpHelper,
		private themeService:ThemeService,
		private authService: AuthService,
		){
	}
	get data():any{
		return this._data;
	}
	set data( data: any){
		var temp = Object.assign({}, data);
		this._data = temp;
		this.subject.next(this._data);
	}


	getApiData(api, api_params,callback=null){
		this.http
			.post(`${environment.server.apiUrl}/`+api, api_params)
			.toPromise()
			.then(res => {
				if(callback){
					console.log("check response",res.json().response)
					callback(res.json().response, api_params);
				}
				this.data = res.json().response;
			})
			.catch(res => {
				console.log('Error:',res);
			});
	}

	getFilterOptions(api,api_params,callback=null){
		this.http
			.post(`${environment.server.apiUrl}/`+api, api_params)
			.toPromise()
			.then(res => {
				var token = res.headers.get('Authorization').split(' ')[1];
				this.setNewToken(token);
				if(callback){
					callback(res.json().response, api_params);
				}
				this.data = res.json().response;
			})
			.catch(res => {
				console.log('Error:',res);
			});
	}

	getReportData(api, api_params,callback=null){
		// api_filter["test"] = true;
		api_params["version"] = environment.api_version;
		const options = {

	        // Ignore this part or  if you want full response you have 
	        // to explicitly give as 'boby'as http client by default give res.json()
	        observe:'response' as 'body',

	       // have to explicitly give as 'blob' or 'json'
	        responseType: 'text' as 'json' 
	    };
		  let token = localStorage.getItem(TOKEN_NAME);
		this.http
			.post(`${environment.server.apiUrl}/`+api, api_params, {responseType:ResponseContentType.Blob})
			.toPromise()
			.then(res => {
				console.log("check Authorization", res.headers.get('Authorization'));
				var token = res.headers.get('Authorization').split(' ')[1];
				console.log("check token",token);
				this.setNewToken(token);
				if(callback){
					console.log(typeof(res));
					callback(res, api_params);
				}
				// this.data = JSON.stringify(res);
			})
			.catch(res => {
				console.log('Error:',res);
			});
	}


	setNewToken(token){
		this.authService.setToken(token);
	}


	subscribe(callback){
		var subscription = this.subject.subscribe(callback);
		if(!_.isEmpty(this._data)){
			callback(this._data);
		}

		return subscription;
	}
	ngOnDestroy() {
		_.map(this.subscriptions, (x: any) => { x.unsubscribe(); });
	}
}
