import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { LocalSettingsService } from 'service/local-setting';
// import { UpdateService } from 'service/sw-service';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { RangePipe } from 'angular-pipes';
// import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { SwUpdate } from '@angular/service-worker';


import * as _ from 'lodash';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  	title = 'app';
    prefix = "";
    suffix = ' | PhIS+ Dashboard';
    version = '';
	constructor(
        // private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
        private angulartics2GoogleGlobalSiteTag:Angulartics2GoogleGlobalSiteTag,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		private translate: TranslateService, 
        private swUpdate: SwUpdate,
		private localSetting: LocalSettingsService){
        this.angulartics2GoogleGlobalSiteTag.startTracking();
        this.version = environment.version;
	}
    ngOnInit(): void {       
        if(environment.production){
            this.swUpdate.available.subscribe(event => {
              console.log('A newer version is now available. Refresh the page now to update the cache');
            });
            
            this.swUpdate.checkForUpdate() 
        }



        this.router.events
        .filter(event => event instanceof NavigationEnd)
        .map(() => this.activatedRoute)
        .map(route => {
            while (route.firstChild) route = route.firstChild;
            return route;
        })
        .filter(route => route.outlet === 'primary')
        .mergeMap(route => route.data)
        .subscribe((event) => {
            this.setTitle(event['title']);
        });

        if(environment.production){
            window.addEventListener("orientationchange", function() {
                window.location.reload();
            }, false);
        }
        
    }
    setTitle(word){
        if (word) {
            // console.log(word);
            if(_.isString(word)){
                this.titleService.setTitle(word + this.suffix);
            }
            else{
                this.titleService.setTitle(_.get(word, 'name', '') + this.suffix);
            }
            
            // this.translate.get(word).subscribe((res:string)=>{
            // });
        }
    }
}
