import { Injectable, OnDestroy} from "@angular/core";
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { DataService } from 'service/data';
import * as _ from 'lodash'; 
declare var moment:any;
@Injectable()
export class DateRange implements OnDestroy{

    private DAY_MILLISECONDS = 86400000;
    private subject = new Subject<any>();
    private message = 1;
    private account:any;
    private _data :any = { 
                tzinfo: moment.tz.guess(),
                // start: moment().subtract(1, 'month'),
                // end: moment().subtract(1, 'day'),
                // min: null,
                // max: null
            };
    private subscriptions = [];
    constructor( private dataService: DataService){
        //get account limit
       this.load();
    }
    get data():any{
        return this._data;
    }
    set data( data: any){
        if(data.start.isValid() && data.end.isValid()){
            console.log('set daterange',data.end.toISOString());
            this.dataService.set('daterange.start', data.start.toISOString());
            this.dataService.set('daterange.end', data.end.toISOString());
        }

        this.subject.next(this._data);
    }

    setDateLimit(){        
        if(this.account){
            if(this.account.viewable_duration){
                this._data.min = moment().subtract(this.account.viewable_duration, 'day');
                this._data.max = moment();
            }
        }
    }
    load(){

        // this.setDateLimit();
        var start = this.dataService.get('daterange.start');
        var end = this.dataService.get('daterange.end');
        var daysRange = this.dataService.get('dateRangeOption');
        if(start && end){   
            this._data.start = moment(start);
            this._data.end = moment(end);         
            this.adjustDate();
        }
        else{         
            this._data.start =  moment("2019-01-01");
            this._data.end = moment("2020-07-31");
        } 
       
         if(!_.isEmpty(daysRange)){
            this._data.daysRange = daysRange;
        }    
        if(this.account){
            this.data = this._data;
        }     
        this.subject.next(this._data);
    }


    adjustDate(){       
        if(this._data.start.isValid() && this._data.end.isValid()){
            if(this._data.min != null && this._data.max != null){
                var startDiff = ((this._data.start - this._data.min) / this.DAY_MILLISECONDS);
                var endDiff = ((this._data.end - this._data.max) / this.DAY_MILLISECONDS);
               
                if(startDiff < 0){ //adjust date if its not all in range.
                    this._data.start = this._data.min;
                    //console.log(startDiff, endDiff);
                    if (startDiff >= endDiff){
                        this._data.end.subtract(startDiff, 'days');
                    }
                    else{
                        this._data.end = this._data.max;
                    }

                }
            }
            
        }       
    }
    getTimezone(){
        try{
            // var utcnow = moment.utc();
            // var timezonenow = utcnow.tz(tzinfo).utcOffset() / 60;
            // if(timezonenow){
            //     this._data.tzinfo = tzinfo;
            //     this.data = this._data;
            // }
            // return timezonenow;
            return moment.utc().tz(this._data.tzinfo).utcOffset();
        }catch(ex){
            // console.log(ex);
        }
        return 0;
    }
    subscribe(callback){
        var subscription = this.subject.subscribe(callback);
        if(this._data.min){
            callback(this.data);
        }
        else{
            this.load();
        }
               
        return subscription;
    }
    ngOnDestroy() {
        _.map(this.subscriptions, (x:any)=>{ x.unsubscribe(); });
        console.log('Service destroy')
    }
}
