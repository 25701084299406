// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//-i18n : customize language directory.
export const environment = {
  production: false,
  i18n: './i18n/',
  version:'1.4.5',
  api_version : 6,
  server: {
  	baseUrl: 'https://phis-web.adqlo.com',
  	apiUrl: 'https://phis-web.adqlo.com/engine/api',
  	web_url : 'https://phis-web.adqlo.com',
  },
  adqlo: {
    contactEmail: 'support@adqlo.com',
    supportEmail: 'support@adqlo.com',
  },
  languages: [ {'code':'en', 'icon':'us'},
				{'code':'cn', 'icon':'cn'}
			],
  themes:{
   'text_light_theme':[
      {'theme':'theme-default','class':'default-theme'},
    ],
    'text_dark_theme':[
      {'theme':'theme-dark','class':'default-dark-theme'},
    ]
  },
  max_concurrent_loading: 2,
};
declare var Chartist:any;
/*
export const settings = {
	chart:{
		line:{},
		bar:{},
		donut:{}
	}
};*/

export const settings = {
	chart:{
		line:{
			options:{
			  	low: 0,
			    plugins: [
			      Chartist.plugins.tooltip({
			      	anchorToPoint : true,
			      	// appendToBody:true,
			      	tooltipOffset: {
				        x: 0,
				        y: -10
			      	}
			      })
			    ]
			},
			animation:{
				delays:25,
				durations:500
			}
		},
		donut:{
			options:{
				chartPadding:20,
				fullWidth:true,
				donut: true,
				donutWidth: '60%',
				showLabel: true,
				labelPosition: 'center',
				ignoreEmptyValues:true,
				plugins: [
			      Chartist.plugins.tooltip({
			      	anchorToPoint:false,
			      	alwaysOnTop:true,
			      	tooltipOffset: {
				        x: 0,
				        y: 0
			      	}
			      	//anchorToPoint : true,
			      	//pointClass: 'ct-slice-donut',

			      })
			    ]
			},
			animation:{
				delays:0,
				durations:500
			}
		},
		bar:{
			options:{
				seriesBarDistance: 10,
				plugins: [
			      Chartist.plugins.tooltip({
			      	anchorToPoint : true,
			      	appendToBody: false,
			      	alwaysOnTop: true,
			      	tooltipOffset: {
				        x: 0,
				        y: -10
			      	}
			      })
			    ]
			},
			animation:{
				delays:50,
				durations:500
			}
		}
	}
}
