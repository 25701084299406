import { Injectable, OnDestroy} from "@angular/core";
import { Http, Response, ResponseContentType } from '@angular/http';
import { Router }    from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AlertService } from 'service/alert.service';
import { ThemeService } from 'service/theme';
import { HttpHelper } from 'service/http';
import { DataService } from 'service/data';
import { HttpClient, HttpHeaders   } from '@angular/common/http';
import { TOKEN_NAME,AuthService  } from 'service/auth.service';
import * as _ from 'lodash'; 
declare var $:any;
declare var moment: any;

@Injectable()
export class IssueList implements OnDestroy{
	private subject = new Subject<any>();
	private subscriptions = null;

	private _data :any = {};
	
	constructor(
		private http: Http,
		private httpHelper: HttpHelper,
		private themeService:ThemeService,
		private authService: AuthService,
		){
	}
	get data():any{
		return this._data;
	}
	set data( data: any){
		var temp = Object.assign({}, data);
		this._data = temp;
		this.subject.next(this._data);
	}

	getItemDetail(param,callback=null){
		var get_item_detail = {};
		get_item_detail["item_desc"] = param.item_desc;
		get_item_detail["item_category_desc"] = param.item_category_desc;
		get_item_detail["sub_group_desc"] = param.sub_group_desc;
		get_item_detail["drug_nondrug_code"] = param.drug_nondrug_code;
		this.getApiData('phis/stock/search',get_item_detail,callback);
	}

	getApiData(api, api_params,callback=null){
		api_params["version"] = environment.api_version;
		this.http
		.post(`${environment.server.apiUrl}/`+api, api_params)
		.toPromise()
		.then(res => {
			if(callback){
				callback(res.json().response, api_params);
			}
			this.data = res.json().response;
		})
		.catch(res => {
			console.log('Error:',res);
		});
	}

	getFilterOptions(api,api_params,callback=null){
		api_params["version"] = environment.api_version;
		this.http
		.post(`${environment.server.apiUrl}/`+api, api_params)
		.toPromise()
		.then(res => {
			if(callback){
				callback(res.json().response, api_params);
			}
			this.data = res.json().response;
		})
		.catch(res => {
			console.log('Error:',res);
		});
	}

	getReportData(api, api_params,callback=null){
		// api_filter["test"] = true;
		api_params["version"] = environment.api_version;
		const options = {

			// Ignore this part or  if you want full response you have 
			// to explicitly give as 'boby'as http client by default give res.json()
			observe:'response' as 'body',

			// have to explicitly give as 'blob' or 'json'
			responseType: 'text' as 'json' 
		};
		let token = localStorage.getItem(TOKEN_NAME);
		this.http
		.post(`${environment.server.apiUrl}/`+api, api_params, {responseType:ResponseContentType.Blob})
		.toPromise()
		.then(res => {
			if(callback){
				callback(res, api_params);
			}
			// this.data = JSON.stringify(res);
		})
		.catch(res => {
			console.log('Error:',res);
		});
	}

	setMonthDistributionData(table_data,month){
		console.log("check table data in set month",table_data);
		var data = table_data;
		var month = month;
		var chart_data = [];
		_.map(month,(value,key) => {
			var month_data = {};
			month_data["date"] = value;
			month_data["name"] = value;
			_.map(data,(state_value,state_key) => {
				_.map(state_value.data,(data_val,data_key) => {
					if(!month_data.hasOwnProperty(data_val["next_layer_value"])){
						month_data[data_val["next_layer_value"]] = 0;
					}
					month_data[data_val["next_layer_value"]] += data_val[value];
				})
			});
			chart_data.push(month_data);
		});
		return chart_data;

	}


	setMonthDistributionDataForAll(table_data,month){
		var data = table_data;
		var month = month;
		var chart_data = [];
		_.map(month,(value,key) => {
			var month_data = {};
			month_data["date"] = value;
			month_data["name"] = value;
			_.map(data,(state_value,state_key) => {
				_.map(state_value.data,(data_val,data_key) => {
					if(!month_data.hasOwnProperty(data_val["next_layer_value"])){
						month_data[data_val["next_layer_value"]] = 0;
					}
					month_data[data_val["next_layer_value"]] += data_val[value];
				})
			});
			chart_data.push(month_data);
		});
		return chart_data;

	}

	setDistributionData(data){
		var state_details = {};
		var distribution_data = [];
		_.map(data,(requester_val,requester_key) => {
			state_details = {};
			state_details["name"] = requester_val.name;
			state_details["value"] = requester_val["summary"]["overall"][0]["quantity"];
			distribution_data.push(state_details);
		});
		return distribution_data;
	}

	setDrugDistributionData(table_data,month){
		var data = table_data;
		var month = month;
		var chart_data = [];
		_.map(month,(value,key) => {
			var month_data = {};
			month_data["date"] = value;
			month_data["name"] = value;
			_.map(data,(state_value,state_key) => {
				_.map(state_value.data,(data_val,data_key) => {
					if(!month_data.hasOwnProperty(data_val["drug_nondrug_code"])){
						month_data[data_val["drug_nondrug_code"]] = 0;
					}
					month_data[data_val["drug_nondrug_code"]] += data_val[value];
				})
			});
			chart_data.push(month_data);
		});
		return chart_data;
	}


	setDrugDistributionFilter(data){
		var state_details = {};
		var new_data = {};
		var drugs = [];
		var drug_distribution_data = [];
		_.map(data,(requester_val,requester_key) => {
			_.map(requester_val.summary.overall, (overall)=>{
				drug_distribution_data.push(overall);
			})
		});

		_.map(drug_distribution_data, (data)=>{
			new_data = {}
			new_data["id"] = data.id;
			new_data["name"] = data.name;
			new_data["is_checked"] = true;
			drugs.push(new_data);
		})
		return _.uniqBy(drugs, 'id');
	}


	setDrugDistributionLegend(data){
		var state_details = {};
		var new_data = {};
		var drugs = [];
		var drug_distribution_data = [];
		_.map(data,(requester_val,requester_key) => {
			_.map(requester_val.summary.overall, (overall)=>{
				drug_distribution_data.push(overall);
			})
		});

		_.map(drug_distribution_data, (data)=>{
			new_data = {}
			new_data["attribute"] = data.id;
			new_data["name"] = data.name;
			new_data["name_as_legend"] = true; 
			drugs.push(new_data);
		})
		return _.uniqBy(drugs, 'attribute');
	}

	setTableColumn(data,table,is_customised_table=false){
		console.log("check table", table);
		var get_data = data;
		var issue_months = get_data[0]["summary"]["issue_months"];
		table.colspan += parseInt(get_data[0]["summary"]["issue_months"].length);

		if(!table.has_month_data){
			_.map(issue_months,(value,key) => {
				var issue_months_setting =  {
					"id" : "",
					"field_name" : value,
					"name" : value,
					"class" : "text-right",
					"is_number" : true,
					"is_clickable" : true,
					"width" : "150px"
				}
				table.table_header.push(issue_months_setting);
	
				table.table_body.push(issue_months_setting);
			});
			table.has_month_data = true;
		}
		
		return table;
	}

	setTableDataForAll(data,params,callback,parent_link){
		var set_table_data = [];
		var merge_uniq_data = [];
		var merge_data = [];
		var default_new_data = {};
		var processed_new_data = [];
		var new_data = _.clone(data);
		var default_link = {};

		_.map(new_data["summary"]["detail"],(value,key) => {
			_.map(value,(item_value,item_key) => {
				var query_params = Object.assign({},params);
				query_params["next_layer_name"] = item_value["next_layer"]["name"];
				query_params["next_layer_id"] = item_value["next_layer"]["id"];
				query_params["next_layer_value"] = item_value["next_layer"]["value"];
				// query_params["selected_issuer_group"] = new_data.name;
				query_params[item_value["next_layer"]["id"]+"_id"] = item_value["next_layer"]["value"];
				query_params[item_value["next_layer"]["id"]+"_name"] = item_value["next_layer"]["name"];
				default_link = {
					"next_layer_name" : item_value["next_layer"]["name"],
					"next_layer_id" : item_value["next_layer"]["id"],
					"next_layer_value" : item_value["next_layer"]["value"],
					"is_clickable":true,
					// "link": "/"+parent_link+"/"+item_value["next_layer"]["id"]+"/"+item_value["next_layer"]["value"],
					"params": query_params,
					"callback" : callback
				};

				_.map(item_value["quantity_by_month"],(month,key) => {
					item_value[key] = month;
					default_link["selected_month"] = key.split("-")[1];
				});

				item_value = _.merge(item_value,default_link);

				processed_new_data.push(item_value);
			});

		});

		return processed_new_data;
	}

	setTableData(data,params,callback,parent_link){
		var set_table_data = [];
		var merge_uniq_data = [];
		var merge_data = [];
		var default_new_data = {};
		var processed_new_data = [];
		var new_data = _.clone(data);

		_.map(new_data[0]["summary"]["overall"],(value,key) => {
			var merge_drug_data = new_data[0]["summary"]["detail"][value.id];
			_.map(merge_drug_data,(drug_val,drug_key) =>{
				merge_data.push(drug_val);
			})
		});
		var get_data_group_by_id = _.uniqBy(merge_data,"facility_code");

		_.map(get_data_group_by_id,(parent_value,key)=>{
			default_new_data = {
				"name" : parent_value.next_layer.name,
				"data" : [],

			}
			var get_filtered_data = _.filter(merge_data,{"facility_code":parent_value.facility_code});
			var parent_query_param = Object.assign({},params);
			parent_query_param["next_layer_name"] = parent_value["next_layer"]["name"];
			parent_query_param["next_layer_id"] = parent_value["next_layer"]["id"];
			parent_query_param["next_layer_value"] = parent_value["next_layer"]["value"];
			parent_query_param[parent_value["next_layer"]["id"]+"_name"] = parent_value["next_layer"]["name"];
			parent_query_param[parent_value["next_layer"]["id"]+"_id"] = parent_value["next_layer"]["value"];
			// parent_query_param["selected_issuer_group"] = new_data[0].name;
			var default_link = {
				"next_layer_name" : parent_value["next_layer"]["value"],
				"next_layer_id" : parent_value["next_layer"]["id"],
				"next_layer_value" : "",
				"is_clickable":true,
				"link": "/"+parent_link+"/"+parent_value["next_layer"]["id"]+"/"+parent_value["next_layer"]["value"],
				"params": parent_query_param,
				"callback" : callback
			};

			if(_.isEmpty(parent_value["next_layer"])){
				default_link["is_clickable"] = false;
			}else{
				if(parent_value["next_layer"]["id"] == "requester"){
					default_link["is_clickable"] = false;
				}
			}

			default_new_data = _.merge(default_new_data,default_link);
			if(!_.isEmpty(get_filtered_data)){
				_.map(get_filtered_data,(value,key) => {
					var query_params = Object.assign({},params);
					query_params["next_layer_name"] = value["next_layer"]["name"];
					query_params["next_layer_id"] = value["next_layer"]["id"];
					query_params["next_layer_value"] = value["next_layer"]["value"];
					query_params[value["next_layer"]["id"]+"_id"] = value["next_layer"]["value"];
					query_params[value["next_layer"]["id"]+"_name"] = value["next_layer"]["name"];
					// query_params["selected_issuer_group"] = new_data[0].name;
					default_link = {
						"next_layer_name" : value["next_layer"]["name"],
						"next_layer_id" : value["next_layer"]["id"],
						"next_layer_value" : value["next_layer"]["value"],
						"is_clickable":true,
						"link": "",
						"params": query_params,
						"callback" : callback
					};

					_.map(value["quantity_by_month"],(month,key) => {
						value[key] = month;
						default_link["selected_month"] = key.split("-")[1];
					});

					value = _.merge(value,default_link);


				})
				default_new_data["data"] = get_filtered_data;
			}
			processed_new_data.push(default_new_data);

		})
		return processed_new_data;
	}

	setCardViewData(data,url,params,callback){
		console.log("card view data",data);
		var get_data = data;
		var return_data = {};
		_.map(get_data,(value,key) => {
			return_data[key] = []; 
			_.map(value,(data_val,data_key) => {
				var process_data = {};
				var query_params = Object.assign({},params);
				query_params["next_layer_name"] = data_val["next_layer"]["name"];
				query_params["next_layer_id"] = data_val["next_layer"]["id"];
				query_params["next_layer_value"] = data_val["next_layer"]["value"];
				query_params[data_val["next_layer"]["id"]+"_name"] = data_val["next_layer"]["name"];
				query_params[data_val["next_layer"]["id"]+"_id"] = data_val["next_layer"]["value"];
				query_params["active_requester_group"] = data_val["requester_group_id"];
				query_params["selected_issuer_group"] = data_val["requester_group_id"];
				var default_link = {
					"next_layer_name" : data_val["next_layer"]["name"],
					"next_layer_id" : data_val["next_layer"]["id"],
					"next_layer_value" : data_val["next_layer"]["value"],
					"is_clickable":true,
					"link": "/"+url+"/"+data_val["next_layer"]["id"]+"/"+data_val["next_layer"]["value"],
					"params": query_params,
					"callback" : callback
				};

				if(_.isEmpty(data_val["next_layer"])){
					default_link["is_clickable"] = false;
				}else{
					if(data_val["next_layer"]["id"] == "requester"){
						default_link["is_clickable"] = false;
					}
				}
				process_data = _.merge(default_link,data_val);
				if(!_.isEmpty(process_data) && process_data != undefined){
					return_data[key].push(process_data);
				}
			});
		});
		return return_data;
	}

	setHeaderTitle(params){
		var header_title = [];
        _.map(params, (title, key)=>{
            if(key == "active_issue_type" || key == "active_requester_group" ){
                header_title[key] = _.startCase(title.split("_").join(" "));
            }    
        })

        return header_title;
    }


	setNewToken(token){
		this.authService.setToken(token);
	}


	subscribe(callback){
		var subscription = this.subject.subscribe(callback);
		if(!_.isEmpty(this._data)){
			callback(this._data);
		}

		return subscription;
	}
	ngOnDestroy() {
		_.map(this.subscriptions, (x: any) => { x.unsubscribe(); });
	}
}
