<div mat-dialog-title>{{ data.title | translate | titlecase }}</div>

<div mat-dialog-content>
    <div class="pb-4" [innerHTML]="data.message | nl2br"></div>
</div>

<div mat-dialog-actions class="text-right confirm-dialog">
	<div class="footer w-100">

	    <button class="btn default-button btn-highlight-1 rounded btn-disabled color-grey mr-3" [hidden]="data?.hide_cancel" mat-button tabindex="-1" (click)="onClick(false)"> Cancel </button>

		<a class="btn default-button btn-highlight-1 rounded float-right float-sm-none" mat-button tabindex="-1" href="{{ data.confirm_link }}" target="_blank" (click)="onClick(true)" *ngIf="data.confirm_link"> {{ data.confirm_button | translate | titlecase }} </a>

	    <button class="btn default-button btn-highlight-1 rounded float-right float-sm-none" mat-button tabindex="-1" (click)="onClick(true)" *ngIf="!data.confirm_link" > {{ data.confirm_button | translate | titlecase }} </button>

	</div>    
</div>
