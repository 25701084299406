import { Injectable, OnDestroy} from "@angular/core";
import { Http, Response, ResponseContentType } from '@angular/http';
import { Router }    from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AlertService } from 'service/alert.service';
import { ThemeService } from 'service/theme';
import { HttpHelper } from 'service/http';
import { DataService } from 'service/data';
import { HttpClient, HttpHeaders   } from '@angular/common/http';
import { TOKEN_NAME,AuthService  } from 'service/auth.service';
import * as _ from 'lodash'; 
declare var $:any;
declare var moment: any;

@Injectable()
export class Budget implements OnDestroy{
	private subject = new Subject<any>();
	private subscriptions = null;

	private _data :any = {};

	private default_link : any  = {
		"name" :"",
		"id" : "", 
		"data" : [], 
		"is_clickable":true,
		"link": "", 
		"params":{}
	}
	
	constructor(
		private http: Http,
		private httpHelper: HttpHelper,
		private themeService:ThemeService,
		private authService: AuthService,
		){
	}
	get data():any{
		return this._data;
	}
	set data( data: any){
		var temp = Object.assign({}, data);
		this._data = temp;
		this.subject.next(this._data);
	}

	getItemDetail(param,callback=null){
		var get_item_detail = {};
		get_item_detail["item_desc"] = param.item_desc;
		get_item_detail["item_category_desc"] = param.item_category_desc;
		get_item_detail["sub_group_desc"] = param.sub_group_desc;
		get_item_detail["drug_nondrug_code"] = param.drug_nondrug_code;
		this.getApiData('phis/stock/search',get_item_detail,callback);
	}

	getApiData(api, api_params,callback=null){
		// api_params["version"] = environment.api_version;
		if(environment.production){
			api_params["version"] = environment.api_version;
		}else{
			api_params["version"] = 4;
		}
		this.http
		.post(`${environment.server.apiUrl}/`+api, api_params)
		.toPromise()
		.then(res => {
			if(callback){
				callback(res.json(), api_params);
			}
			this.data = res.json().response;
		})
		.catch(res => {
			var data = {};
			data["data"] = [];
			data["res"] = res;
			if(callback){
				callback(res.json(), api_params);
			}
		});
	}

	getFilterOptions(api,api_params,callback=null){
		this.http
		.post(`${environment.server.apiUrl}/`+api, api_params)
		.toPromise()
		.then(res => {
			if(callback){
				callback(res.json().response, api_params);
			}
			this.data = res.json().response;
		})
		.catch(res => {
			console.log('Error:',res);
		});
	}

	getReportData(api, api_params,callback=null){
		// api_filter["test"] = true;
		api_params["version"] = environment.api_version;
		const options = {

			// Ignore this part or  if you want full response you have 
			// to explicitly give as 'boby'as http client by default give res.json()
			observe:'response' as 'body',

			// have to explicitly give as 'blob' or 'json'
			responseType: 'text' as 'json' 
		};
		let token = localStorage.getItem(TOKEN_NAME);
		this.http
		.post(`${environment.server.apiUrl}/`+api, api_params, {responseType:ResponseContentType.Blob})
		.toPromise()
		.then(res => {
			var token = res.headers.get('Authorization').split(' ')[1];
			this.setNewToken(token);
			if(callback){
				callback(res, api_params);
			}
			// this.data = JSON.stringify(res);
		})
		.catch(res => {
			console.log('Error:',res);
		});
	}

	setTableData(api_data,link,params,view_type,has_next_layer){
		var get_link = this.default_link;
		get_link["is_clickable"] = has_next_layer;

		_.map(api_data,(value,key) => {
			value["data"] = [];
			var query_params = Object.assign({},params);
			if(view_type =="state"){
				query_params["selected_state"] = value["id"];
				query_params["state_name"] =value["name"];
			}else if(view_type =="budget"){
				query_params["selected_district"] = value["id"];
				query_params["district_name"] = value["name"];
			}
			get_link["name"]  = value["name"];
			get_link["id"]  = value["id"];
			get_link["params"]  = query_params;

			if(!_.isEmpty(link)){
				get_link["link"] = link+"/"+view_type+"/"+value["id"]; 
			}

			value = _.merge(value,get_link);

			_.map(value.summary.detail,(data_val,data_key) => {
				_.map(data_val,(data_value,data_key) => {
					value["data"].push(data_value);
					if(view_type == "state"){
						value["link"] = link+"/"+data_value["next_layer"]["id"]+"/"+data_value["next_layer"]["value"];
						value["params"]["selected_"+data_value["next_layer"]["id"]] = data_value["next_layer"]["value"];
						value["params"][data_value["next_layer"]["id"]+"_name"] = data_value["next_layer"]["name"];
					}
					
				})
			});

		});
		return api_data;
	}

	getUtilizeLiabilityBugetData(api_data){

		var allocation = api_data.data.budget["allocation"];
		var utilized_amount = api_data.data.budget["utilized_amount"];
		var liability_amount = api_data.data.budget["liability_amount"];

		var comparison_chart = {};
		var compare_allocation_utilized = [];

		_.map(allocation,(value,key) => {
			comparison_chart={};
			comparison_chart["name"] = value.name;
			comparison_chart["type"] = value.name;
			comparison_chart["allocation"] = value.allocation;
			var get_utilized_amount = _.filter(utilized_amount,{"id":value.id});
			comparison_chart["utilized_amount"] = get_utilized_amount[0]["allocation"];

			var get_liability_amount = _.filter(liability_amount,{"id":value.id});
			comparison_chart["liability_amount"] = get_liability_amount[0]["allocation"];

			comparison_chart["utilized_liability_amount"] = get_utilized_amount[0]["allocation"]+get_liability_amount[0]["allocation"];
			compare_allocation_utilized.push(comparison_chart);
		})
		return compare_allocation_utilized;
	}


	setNewToken(token){
		this.authService.setToken(token);
	}


	subscribe(callback){
		var subscription = this.subject.subscribe(callback);
		if(!_.isEmpty(this._data)){
			callback(this._data);
		}

		return subscription;
	}
	ngOnDestroy() {
		_.map(this.subscriptions, (x: any) => { x.unsubscribe(); });
	}
}
