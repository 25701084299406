import { Injectable, OnDestroy} from "@angular/core";
import { Http, Response, ResponseContentType } from '@angular/http';
import { Router,ActivatedRoute }    from '@angular/router';
import { RouteParams } from 'model/route-params';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AlertService } from 'service/alert.service';
import { ThemeService } from 'service/theme';
import { HttpHelper } from 'service/http';
import { DataService } from 'service/data';
import { HttpClient, HttpHeaders   } from '@angular/common/http';
import { TOKEN_NAME,AuthService  } from 'service/auth.service';
import * as _ from 'lodash'; 
declare var $:any;
declare var moment: any;

@Injectable()
export class Procurement implements OnDestroy{
	private subject = new Subject<any>();
	private subscriptions = null;

	private _data :any = {};

    private default_link : any  = {
        "name" :"",
        "id" : "", 
        "data" : [], 
        "is_clickable":true,
        "link": "", 
        "params":{}
    }

    constructor(
        private http: Http,
        private httpHelper: HttpHelper,
        private themeService:ThemeService,
        private authService: AuthService,
        public routeParams: RouteParams,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        ){
    }
    get data():any{
        return this._data;
    }
    set data( data: any){
        var temp = Object.assign({}, data);
        this._data = temp;
        this.subject.next(this._data);
    }

    getItemDetail(param,callback=null){
        var get_item_detail = {};
        get_item_detail["item_desc"] = param.item_desc;
        get_item_detail["item_category_desc"] = param.item_category_desc;
        get_item_detail["sub_group_desc"] = param.sub_group_desc;
        get_item_detail["drug_nondrug_code"] = param.drug_nondrug_code;
        this.getApiData('phis/stock/search',get_item_detail,callback);
    }

    getApiData(api, api_params,callback=null){
        // api_params["version"] = environment.api_version;
        if(environment.production){
            api_params["version"] = environment.api_version;
        }else{
            api_params["version"] = 5;
        }
        this.http
        .post(`${environment.server.apiUrl}/`+api, api_params)
        .toPromise()
        .then(res => {
            if(callback){
                callback(res.json().response, api_params);
            }
            this.data = res.json().response;
        })
        .catch(res => {
            console.log('Error:',res);
        });
    }

    getFilterOptions(api,api_params,callback=null){
        if(environment.production){
            api_params["version"] = environment.api_version;
        }else{
            api_params["version"] = 5;
        }
        this.http
        .post(`${environment.server.apiUrl}/`+api, api_params)
        .toPromise()
        .then(res => {
            if(callback){
                callback(res.json().response, api_params);
            }
            this.data = res.json().response;
        })
        .catch(res => {
            console.log('Error:',res);
        });
    }

    getReportData(api, api_params,callback=null){
        // api_filter["test"] = true;
        api_params["version"] = environment.api_version;
        const options = {

            // Ignore this part or  if you want full response you have 
            // to explicitly give as 'boby'as http client by default give res.json()
            observe:'response' as 'body',

            // have to explicitly give as 'blob' or 'json'
            responseType: 'text' as 'json' 
        };
        let token = localStorage.getItem(TOKEN_NAME);
        this.http
        .post(`${environment.server.apiUrl}/`+api, api_params, {responseType:ResponseContentType.Blob})
        .toPromise()
        .then(res => {
            var token = res.headers.get('Authorization').split(' ')[1];
            this.setNewToken(token);
            if(callback){
                callback(res, api_params);
            }
            // this.data = JSON.stringify(res);
        })
        .catch(res => {
            console.log('Error:',res);
        });
    }

    setChartData(api_data,data,parent_field_name,data_type){
        data[data_type].data = api_data.data[parent_field_name][data_type];

        if(!_.isEmpty(data[data_type].sub_field)){
            data[data_type].data =  this.processDataForComparisonChart(data[data_type].data, data, data_type);
        }

        if(data[data_type]["active_chart"]["id"] == "treemap"){
            this.processIntoTreeMapData(api_data,data,parent_field_name,data_type);
        }
        return data[data_type].data;
    }

    processIntoTreeMapData(api_data,data,parent_field_name,data_type){

        var filter_data = {};
        var process_data = {};
        var final_process_data = [];

        _.map(api_data.data[parent_field_name][data_type],(value,key) => {
            filter_data[value.id] = {};
            filter_data[value.id]["data"] = [];
            console.log("check processIntoTreeMapData",value,data_type);
            _.map(value.drug_nondrug_code,(data_val,data_key) => {
                process_data = {};
                process_data["name"] = data_val.name;
                process_data["drug_nondrug_code"] = [];
                process_data["drug_nondrug_code"].push(data_val);
                filter_data[value.id]["data"].push(process_data);
            });

        });
        data[data_type]["data"] = filter_data;

    }

    processDataForComparisonChart(data,chart,field_name){

        _.map(data,(value,key) => {
            _.map(value[chart[field_name]["sub_field"]],(data_value,data_key) => {
                value[data_value.id] = 0;
                value[data_value.id] = data_value["purchase_amount"];
            })
        })

        return data;
    }

    setLinkInData(data,link,params,view_type){
        var default_link = {
            "name" : "",
            "id" : "",
            "data" : [], 
            "is_clickable":true,
            "link": "",
            "params": []
        }

        _.map(data,(value,key) =>{
            default_link
        })

    }

    setCardViewTableData(api_data,link,params,view_type,has_next_layer){
        var get_link = this.default_link;
        get_link["is_clickable"] = has_next_layer;

        _.map(api_data,(value,key) => {
            value["data"] = [];
            var query_params = Object.assign({},params);
            if(view_type =="state"){
                query_params["selected_state"] = value["id"];
                query_params["state_name"] =value["name"];
            }else if(view_type =="budget"){
                query_params["selected_district"] = value["id"];
                query_params["district_name"] = value["name"];
            }
            get_link["name"]  = value["name"];
            get_link["id"]  = value["id"];
            get_link["params"]  = query_params;

            if(!_.isEmpty(link)){
                get_link["link"] = link+"/"+view_type+"/"+value["id"]; 
            }

            value = _.merge(value,get_link);

            _.map(value.summary.detail,(data_val,data_key) => {
                _.map(data_val,(data_value,data_key) => {
                    value["data"].push(data_value);
                })
            });

        });
        return api_data[0]["data"];
    }

    setTableData(data,link,params,view_type,parent_field_name,field_name, is_set_link=false, is_clickable=true){
        var process_data = {};
        var return_data = [];
        var data_group_by_id = _.uniqBy(data.data[parent_field_name][field_name],"id");
        var item_group = _.uniqBy(data.data[parent_field_name][field_name],"item_group");
        var purchase_order_type = _.uniqBy(data.data[parent_field_name][field_name],"purchase_order_type");


        _.map(data_group_by_id,(value,key) => {   
            var query_params = Object.assign({},params);
            if(view_type =="state"){
                query_params["selected_state"] = value["id"];
                query_params["state_name"] =value["name"];
            }else if(view_type =="district"){
                query_params["selected_district"] = value["id"];
                query_params["district_name"] = value["name"];
            }

            var default_link = {
                "name" : value["name"],
                "id" : value["id"], 
                "data" : [], 
                "is_clickable":true,
                "link": "", 
                "params":query_params
            }

            default_link["is_clickable"]  = is_clickable;
            if(!_.isEmpty(link)){
                default_link["link"] = link+"/"+view_type+"/"+value["id"];
            }

            value = _.merge(value,default_link);

            return_data.push(default_link);

        });

        if(is_set_link){
            return data_group_by_id;
        }

        _.map(return_data,(value,key) => {
            var get_all_related_data = _.filter(data.data[parent_field_name][field_name],{"id":value["id"]});
            var sum_by_item_group = 0;
            var active_item_group = "";

            var item_group_total = _(get_all_related_data)
            .groupBy('item_group')
            .map((objs, key) => ({
                "item_group_total" : key,
                "total": _.sumBy(objs, 'purchase_amount'),
            }),
            )
            .value();

            var group_by_item_group = _.groupBy(get_all_related_data,"item_group");
            var sum_item_group_total = 0;

            _.map(group_by_item_group,(group_val,group_key) => {
                var data_details = {};
                _.map(group_val,(data_val,data_key) => {

                    if(_.isEmpty(data_details)){
                        data_details = data_val;
                    }

                    var prefix =  data_val["item_group"]+"_";
                    var variable_name = data_val["item_group"]+"_"+data_val["purchase_order_type"];
                    var get_total_by_item_group = _.filter(item_group_total,{"item_group_total":data_val["item_group"]});

                    data_details["prefix"] =prefix;
                    data_details[variable_name] = data_val["purchase_amount"];
                    data_details[prefix+"total"] = get_total_by_item_group[0]["total"];
                    data_details["total"] = get_total_by_item_group[0]["total"];
                    
                });
                value["data"].push(data_details);
            });

            var purchase_order_total = _(get_all_related_data)
            .groupBy('purchase_order_type')
            .map((objs, key) => ({
                "key" : key,
                "total": _.sumBy(objs, 'purchase_amount'),
            }),
            )
            .value();

            var total = {
                "item_group" : "Total",
                "prefix" : "total_",
            }

            _.map(purchase_order_total,(value,key) => {
                total[total["prefix"]+value["key"]] = value["total"];
            });
            total[total["prefix"]+"total"] = _.sumBy(value["data"],"total");
 
            value["data"].push(total);
        });
        return return_data;
    }


    setDrugData(api_data,link,params,view_type,has_next_layer){
        var get_link = this.default_link;
        get_link["is_clickable"] = has_next_layer;
        _.map(api_data,(value,key) => {
            value["data"] = [];
           
            var query_params = Object.assign({},params);
            if(view_type =="state"){
                query_params["selected_state"] = value["id"];
                query_params["state_name"] =value["name"];
            }else if(view_type =="district"){
                query_params["selected_district"] = value["id"];
                query_params["district_name"] = value["name"];
            }
            get_link["name"]  = value["name"];
            get_link["id"]  = value["id"];
            get_link["params"]  = query_params;

            if(!_.isEmpty(link)){
                get_link["link"] = link+"/"+view_type+"/"+value["id"]; 
            }

            value = _.merge(value,get_link);
            _.map(value.summary.detail,(data_val,data_key) => {
                _.map(data_val,(data_value,data_key) => {
                    if(!_.isEmpty(data_value.next_layer)){
                        if(view_type =="state"){
                            value['params']["selected_"+data_value.next_layer.id] = data_value.next_layer.value;
                            value['params'][data_value.next_layer.id] = data_value.next_layer.value;
                            value['params'][data_value.next_layer.id+"_name"] = data_value.next_layer.name;
                            if(data_value.next_layer.id == "individual"){
                                delete value["params"]["selected_state"];
                                delete value["params"]["state"];
                                delete value["params"]["state_name"];
                            }
                        }
                        if(data_value.facility_code == "cpfl-anp" && data_value.next_layer.id == "facility"){
                            var covid_params = _.cloneDeep(params);
                            data_value["allow_drug_breakdown"] = true;
                            data_value["link"]  =link+"/drug-detail/"+data_value["drug_nondrug_code"];
                            covid_params["selected_drug_code"] = data_value["drug_nondrug_code"];
                            data_value["query_params"] =covid_params;
                        }
                        value["data"].push(data_value);
                        if(view_type == "district"){
                            var data_value_params = _.cloneDeep(params);
                            data_value["params"] = {};
                            value["is_clickable"] = !data_value["allow_drug_breakdown"];
                            if(data_value["allow_drug_breakdown"]){
                                data_value["link"]  =link+"/drug-detail/"+data_value["drug_nondrug_code"];
                                data_value_params["selected_district"] = data_value["next_layer"]["value"];
                                data_value_params["selected_drug_code"] = data_value["drug_nondrug_code"];
                                data_value_params["district_name"] = data_value["next_layer"]["name"];
                            }
                            data_value["query_params"] = data_value_params; 
                        }else if(view_type == "individual"){
                            value["is_clickable"] = data_value["allow_drug_breakdown"];
                        }

                    } else {
                        value["data"].push(data_value);
                    }
                });
            });
        });
        return api_data;
    }

    setYearInTitle(data,field_name,year){
        var title = data.title.split(" ");
        if(title[title.length-1] == "" || title[title.length-1] == "Year"){
            data.title = data.title+" "+year;
            data.desc = data.desc+" "+year;
            if(field_name == "procurement_by_state_summary" || field_name == "procurement_by_state_item_group" || field_name == "procurement_by_state_po_type" ){
                data.title = data.title+" - By State";
                data.desc = data.desc+" - By State";
            }else if(field_name == "procurement_by_district_summary"|| field_name == "procurement_by_district_po_type" || field_name =="procurement_by_district_item_group"){
                data.title = data.title+" - By District";
                data.desc = data.desc+" - By District";
            }

        }


        return data;
    }

    onDrugCodeClick(data,params){
        var query = Object.assign({},params);
        var default_link = "/procurement/list/overall/drug";
        query["drug_nondrug_code"] = [];
        query["drug_nondrug_code"].push(data.id);

        params = query;

        if(query.hasOwnProperty("selected_state")){
            default_link = default_link+"/state/"+query["selected_state"];
        }

        if(query.hasOwnProperty("selected_district")){
            default_link = default_link+"/district/"+query["selected_district"];
        }

        this.router.navigate([default_link], { relativeTo: this.activatedRoute, queryParams: query});
    }

    setNewToken(token){
        this.authService.setToken(token);
    }


    subscribe(callback){
        var subscription = this.subject.subscribe(callback);
        if(!_.isEmpty(this._data)){
            callback(this._data);
        }

        return subscription;
    }
    ngOnDestroy() {
        _.map(this.subscriptions, (x: any) => { x.unsubscribe(); });
    }
}
