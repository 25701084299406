import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'share-confirm-dialog-without-link',
  templateUrl: './confirm-dialog-without-link.component.html',
  styleUrls: ['./confirm-dialog-without-link.component.scss']
})
export class ConfirmDialogWithoutLinkComponent{
  constructor(
    public translate:TranslateService,
    public dialogRef: MatDialogRef<ConfirmDialogWithoutLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    console.log('data:', data);
  }
  onCloseClick(): void {
    this.dialogRef.close();
  }
}
