import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'service/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from "@angular/common";
import { Http, Response, Headers } from '@angular/http';
import { environment } from 'environments/environment';
import { ThemeService } from 'service/theme';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
	selector: 'header.topbar',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	@Input('options') options:any;
	b_show: boolean = true;
	themes:any;
	selectedTheme: string = "theme-light";
	constructor(
		private authService: AuthService,
		private location: Location,
		private router: Router,
		private http: Http,
		public theme:ThemeService, 
		public translate: TranslateService
	) {
		this.themes = theme.getThemes();
		this.selectedTheme = theme.getTheme();

		if (this.router.url == '/validate-account') {
			this.b_show = false;
		}
	}

	ngOnInit() {
		$('.dropdown-toggle').dropdown();
		// When the user scrolls the page, execute myFunction 
		// Get the header
		var header = $('.header-section');


	}

	backToDashboard() {
		if (this.router.url != '/validate-account') {
			this.router.navigate(['/dashboard']);
		}
	}

	logout() {
		this.authService.logout()
			.then(res => {
				sessionStorage.setItem('logout','true');
				location.reload();
				this.location.go('/auth/login');
				//this.router.navigate(['/auth/login'])
			}).catch(res => {
				console.log(res);
				sessionStorage.setItem('logout','true');
					this.location.go('/auth/login');
				// logout error
			});
	}

	changeTheme(selectedTheme){
		console.log(selectedTheme);
		this.selectedTheme = selectedTheme;
		this.theme.setTheme(selectedTheme);
	}
	




}
