import { Injectable, OnDestroy} from "@angular/core";
import { environment } from 'environments/environment';
import { DataService } from 'service/data';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_none from "@amcharts/amcharts4/themes/amcharts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_frozen from "@amcharts/amcharts4/themes/frozen";
import am4themes_moonrisekingdom from "@amcharts/amcharts4/themes/moonrisekingdom";
import am4themes_spiritedaway from "@amcharts/amcharts4/themes/spiritedaway";


import { ThemeService} from 'service/theme';

//import * as io from 'socket.io-client';
import * as _ from 'lodash'; 
declare var $: any;

@Injectable()
export class ChartService implements OnDestroy{
	themeObservable :any;
	selectedTheme: any;
	selectedChartTheme : any;
	DEFAULT_COLOR = [
		am4core.color("#5797de"),
		am4core.color("#f5587d"),
		am4core.color("#FF9671"),
		am4core.color("#FFC75F"),
		am4core.color("#6435c1"),
		am4core.color("#F9F871"),
	];
	private subject = new Subject<any>();
	constructor(private theme :ThemeService){
		this.themeObservable = this.theme.subscribe((selectedTheme:any)=>{
			this.selectedTheme = selectedTheme;      
			if(this.selectedTheme == 'theme-dark'){
				am4core.useTheme(am4themes_dark);
			}else{
				am4core.unuseTheme(am4themes_dark);           
			}

			am4core.useTheme(am4themes_animated);
		});

		this.switchChartTheme('animated');
		//set charts to be animated
		am4core.useTheme(this.am4themes_salesTheme);
		
		// //console.log('am4core.useTheme.');
	}

	verify(){ //remove watermark
		$('g').filter(function() {   
			return $(this).attr('aria-labelledby') && $(this).attr('aria-labelledby').endsWith('-title');
		}).filter(function(){
			return this.textContent == 'Chart created using amCharts library';
		}).hide();
	}

	getThemes():any{
		return 'animated';
	}

	subscribe(callback){
		var subscription = this.subject.subscribe(callback);
		callback(this.selectedChartTheme);
		return subscription;
	}
	getDefaultColor(){
		return this.DEFAULT_COLOR;
	}

	pending_queues = [];
	running_queues = [];
	queues:any = {
	};

	createChart(id, chart_type, options){
		// this.pending_queues.push({
		// 	'id': id,
		// 	'chart_type': chart_type,
		// 	'options': options,
		// });

		// var previous_running = _.find(this.running_queues, (x)=>{ return x['id'] == id}, null);
		// if(previous_running){
		// 	previous_running['cancel'] = true;
		// }
		// return this.runQueue();
		let chart:any= am4core.create(id, chart_type);

		var split_id = _.cloneDeep(id).split("-");

		if(split_id[0] == "treemap"){
			am4core.unuseTheme(am4themes_animated);  
			am4core.options.queue = true;
		}
	
			// if(options.allow_export_file == true){
			// 	chart.exporting.menu = new am4core.ExportMenu();
			// 	chart.exporting.menu.align = "right";
			// 	chart.exporting.menu.verticalAlign = "top";
				try{
					options.keepTainted = true;
					let onDownloadClick:any = options.onDownloadClick;
					if(!_.isEmpty(onDownloadClick)){
						chart.subscription = onDownloadClick.subscribe((download_format)=>{
							this.exportChart(chart, download_format);
						})
					}
				}catch(ex){
					console.log(ex);
				}
				
			// }

			//Generate Scrollbar X & Y
			this.generateScrollbar(chart, options);

			//Generate Legend
			if(_.get(options, 'legend.show', false) == true){
				this.generateLegend(chart, options.legend);
			}

			//Generate Chart Cursor
			if(_.get(options, 'chart_cursor.show', false) == true){ //previous variable => 'allow_chart_cursor'
				chart.cursor = new am4charts.XYCursor();
				chart.cursor.lineX.disabled = _.get(options, 'chart_cursor.disable_lineX', false);
				chart.cursor.lineY.disabled = _.get(options, 'chart_cursor.disable_lineY', false);
			}	

			//Generate Chart Title
			if(_.get(options, 'chart_title.show', false) == true){
				let title = chart.titles.create();
				title.text = options.chart_title.text;
				title.fontSize = 22;
				title.marginBottom = 30;
				title.paddingTop = 20;
			}	

			//Generate Chart Label
			if(_.get(options, 'label.chart_label.show', false) == true){
				this.generateChartLabel(chart, options.label.chart_label.text, options.label.chart_label);
			}

			//Generate Number Format
			if(_.get(options, 'is_percentage', false) == true){
				this.generateNumberFormat(chart, 'percent');
			}else{
				this.generateNumberFormat(chart, '');
			}
			

			//Generate Chart Padding
			if(_.has(options, 'padding') == true){
				let padding = options.padding;

				chart.paddingTop = padding.top;
				chart.paddingRight = padding.right;
				chart.paddingBottom = padding.bottom;
				chart.paddingLeft = padding.left;
			}

			//Generate Chart Margin
			if(_.has(options, 'margin') == true){
				let margin = options.margin;

				chart.marginTop = margin.top;
				chart.marginRight = margin.right;
				chart.marginBottom = margin.bottom;
				chart.marginLeft = margin.left;
			}

			//Set Sentiment Color for Chart
			if(options.use_sentiment_color_list == true){
				chart.colors.list = [
					  am4core.color("#5797de"),
					  am4core.color("#ffd700"),
					  am4core.color("#6435c1"),
					  am4core.color("#f5587d")
				];

				if(options.use_dark_color_sentiment == true){
					chart.colors.list = [
						  am4core.color("#00008B"),
						  am4core.color("#FF8000"),
						  am4core.color("#6435c1"),
						  am4core.color("#f5587d")
					];
				}
			}

		return chart;
	}

	exportChart(chart, download_format){
		if(!_.isEmpty(chart)){
			let format = _.get(download_format, 'extension', '');
			chart.exporting.filePrefix = 'adqlo-export';

			if(this.selectedTheme == 'theme-dark'){
				var gradient = new am4core.LinearGradient();
				gradient.stops.push({color:am4core.color("#1c3f67")})
				gradient.stops.push({color:am4core.color("#0d1834")})
				gradient.rotation = 90;
				chart.background.fill = gradient;
				chart.background.visible = true;
			}
			
			chart.exporting.export(format);

			chart.exporting.events.on("exportfinished", (ev) => {
			  chart.background.visible = false;
			});	
		}
		
	}

	disposeChart(chart){
		if(chart){
			if(chart.subscription){
				chart.subscription.unsubscribe();
			}
			chart.dispose();
		}
		return null;
	}

	generateScrollbar(chart, options) {
		if (_.get(options, 'scrollbar.x.show', false) == true) {
			chart.scrollbarX = new am4core.Scrollbar();
			chart.scrollbarX.marginLeft = 0;
			chart.scrollbarX.marginBottom = 30;
		}
		if (_.get(options, 'scrollbar.y.show', false) == true) {
			var scrollbar_position = chart.leftAxesContainer;
			if(options.scrollbar.y.hasOwnProperty("position")) {
				scrollbar_position = _.isEqual(options.scrollbar.y.position, "right") ? chart.rightAxesContainer : chart.leftAxesContainer;
			}
			chart.scrollbarY = new am4core.Scrollbar();
			chart.scrollbarY.parent = scrollbar_position;
			chart.scrollbarY.toBack(); // to generate the scrollbar at most outside
		}
		this.setScrollbarColor(chart, _.get(options, 'scrollbar.color', null));
	}

	generateXYChartScrollbar(chart, series){
		chart.scrollbarX = new am4charts.XYChartScrollbar();
		chart.scrollbarX.series.push(series);
		chart.scrollbarX.parent = chart.bottomAxesContainer;
		this.setScrollbarColor(chart, null);
	}

	generateNumberFormat(chart, number_format){
		if(_.isEmpty(number_format)){
			number_format = "#,###.###a";
		}
		if(number_format == "percent"){
			number_format = "#.##'%'"
		}
		chart.numberFormatter.numberFormat = number_format; 
		chart.numberFormatter.bigNumberPrefixes = [
			{ "number": 1e+3, "suffix": "K" },
			{ "number": 1e+6, "suffix": "M" },
			{ "number": 1e+9, "suffix": "B" },
			{ "number": 1e+12, "suffix": "T" }
		];
		//set the smallest number for small number prefixes
		chart.numberFormatter.smallNumberThreshold = 0.000000001; 
	}

	generateChartLabel(chart, title, options){
		let chart_label = chart.chartContainer.createChild(am4core.Label);
		chart_label.text = title;
		chart_label.align = _.get(options, 'align', 'center');
		chart_label.marginTop = _.get(options, 'margin.top', 0);
		chart_label.marginBottom = _.get(options, 'margin.bottom', 0);
		chart_label.marginLeft = _.get(options, 'margin.left', 0);
		chart_label.marginRight = _.get(options, 'margin.right', 0);
		// return chart_title;
	}

	generateLegend(chart, options){
		if(_.isEmpty(options.template_text)){
			options.template_text = 'title';
		}
		var legend = new am4charts.Legend();

		legend.position = _.get(options, 'position', 'bottom');
		legend.labels.template.text = "[bold]{" +_.get(options, 'template_text', 'title') + "}[/]";
		if(_.has(options, 'font_size')){
			legend.fontSize = options.font_size;
		}
	
		if(_.get(options, 'truncate', false) == true){
			legend.labels.template.maxWidth = 150;
			legend.labels.template.truncate = true;
		}
				
		chart.legend = legend;
	}

	generateAxisTitle(xAxis, yAxis, options, is_opposite){
		this.generateXAxisTitle(xAxis, options);
		this.generateYAxisTitle(yAxis, options, is_opposite);		
	}

	generateXAxisTitle(xAxis, options){
		if(_.has(options,'xAxis.title')){
			xAxis.title.text = _.get(options, 'xAxis.title', '');
			xAxis.title.fontWeight = 750;
			xAxis.title.marginBottom = 15;
			// xAxis.title.marginTop = 15;
		}
	}

	generateYAxisTitle(yAxis, options, is_opposite){
		if(is_opposite == true){
			if(_.has(options,'yAxis.opposite_title')){
				yAxis.title.text = _.get(options, 'yAxis.opposite_title', '');
			}
		}else{
			if(_.has(options,'yAxis.title')){
				yAxis.title.text = _.get(options, 'yAxis.title', '');
			}
		}

		yAxis.title.fontWeight = 750;
	}

	rotateText(element, tooltip_text){
		element.rotation = 270;
		// element.truncate  = true;
		element.maxWidth = 130;
		element.maxHeight = 350;
		element.verticalCenter = "middle";
		element.horizontalCenter = "right";	
		element.wrap = true;
		element.tooltipText = tooltip_text;
	}

	setTagCloudColor(min, max, options, hover_state, theme_color){
		if(_.isEmpty(theme_color)){
			theme_color = this.getThemeColor();
		}

		let color = []; // this color array store [color code for min, color code for max, color code for hover state]

		if(options.is_sentiment == true){
			color = [am4core.color(options.min_color),am4core.color(options.max_color)];
		}else{
			color = [am4core.color(theme_color['min']),am4core.color(theme_color['max']),am4core.color(theme_color['hover'])];
		}

		return  color;
	}

	setHeatmapColor(column, theme_color){
		if(_.isEmpty(theme_color)){
			theme_color = this.getThemeColor();
		}

		column.stroke = am4core.color(theme_color['heatmap_stroke']);
	}

	setPictorialColorList(){
		let color = [
			am4core.color("rgba(214, 214, 214, 0.66)"),
			am4core.color("#6794dc")
		];

		return color;
	}

	setGaugeAxisHandColor(){
		let color = ["#67b7dc","#6771dc","#a367dc","#dc67ce","#dc6788"];

		return color;
	}

	setScrollbarColor(chart, theme_color){
		if(_.isEmpty(theme_color)){
			theme_color = this.getThemeColor();
		}
		if(chart.scrollbarX){
			chart.scrollbarX.background.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarX.thumb.background.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarX.startGrip.background.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarX.endGrip.background.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarX.startGrip.background.states.getKey('hover').properties.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarX.startGrip.background.states.getKey('down').properties.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarX.thumb.background.states.getKey('hover').properties.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarX.thumb.background.states.getKey('down').properties.fill = am4core.color(theme_color['scrollbar']);
		}
		if(chart.scrollbarY){
			chart.scrollbarY.background.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarY.startGrip.background.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarY.endGrip.background.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarY.thumb.background.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarY.startGrip.background.states.getKey('hover').properties.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarY.startGrip.background.states.getKey('down').properties.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarY.thumb.background.states.getKey('hover').properties.fill = am4core.color(theme_color['scrollbar']);
			chart.scrollbarY.thumb.background.states.getKey('down').properties.fill = am4core.color(theme_color['scrollbar']);
		}
	}

	switchChartTheme(theme){

		am4core.unuseTheme(this.selectedChartTheme);
		var selected_theme;
		switch(theme) {
			case 'animated' : 
				selected_theme = this.am4themes_salesTheme.bind(this);
				break;
			case 'dataviz' : 
				selected_theme = am4themes_dataviz;
				break;
			case 'material' : 
				selected_theme = am4themes_material;
				break;
			case 'kelly' : 
				selected_theme = am4themes_kelly;
				break;
			case 'dark' : 
				selected_theme = this.am4themes_futuristicTheme.bind(this);
				break;
			case 'frozen' : 
				selected_theme = am4themes_frozen;
				break;
			case 'moonrisekingdom' : 
				selected_theme = am4themes_moonrisekingdom;
				break;
			case 'spiritedaway' : 
				selected_theme = am4themes_spiritedaway;
				break;
			default:
				selected_theme = this.am4themes_gradientTheme.bind(this);
				break;
		}
		// this.selectedTheme = selected_theme;
		// this.subject.next(selected_theme);
		am4core.useTheme(am4themes_animated);
		// am4core.useTheme(selected_theme);

		am4core.useTheme(this.am4themes_salesTheme);
		// this.selectedChartTheme = selected_theme;
	}

	am4themes_galaxyTheme(target) {
		if (target instanceof am4core.ColorSet) {
			return target.list = [
			am4core.color("#6435c1"),
			am4core.color("#d16eff"),
			am4core.color("#7868D8"),
			am4core.color("#00a4ff"),
			];
		}
	}

	am4themes_gradientTheme(target) {
		let gradient = new am4core.LinearGradient();
		if (target instanceof am4core.ColorSet) {
			return target.list = [
				am4core.color("#5797de"),
				am4core.color("#6435c1"),
				am4core.color("#f5587d"),
				am4core.color("#00a4ff"),
			];
		}
	}

	am4themes_salesTheme(target){

		if (target instanceof am4core.ColorSet) {
			return target.list = [

			// am4core.color("#5797de"),
			// am4core.color("#6435c1"),
			// am4core.color("#f5587d"),
			// am4core.color("#ff9671"),
			// am4core.color("#ffc75f"),
			// Contrast color refer flattastic-color-palette by erigon https://www.pinterest.com/pin/172966441914141897/
			am4core.color("#8E8CD8"),
			
			am4core.color("orange"),
			am4core.color("#37BC9B"),
			am4core.color("#F6BB42"),
			am4core.color("#E9573F"),
			am4core.color("#D770AD"),
			am4core.color("#DA4453"),
			am4core.color("#434A54"), 
			am4core.color("#4A89DC"),
			am4core.color("#0B0C10"),
			am4core.color("#1F2833"),
			am4core.color("#C5C6C7"),
			am4core.color("#66FCF1"),
			am4core.color("#45A29E"),
			am4core.color("#8764B8"),
			am4core.color("#37BC9B"),
			am4core.color("#2D7D9A"),
			am4core.color("#00B294"),
			am4core.color("#10893E"),
			am4core.color("#FF8C00"),
			am4core.color("#DA3B01"),
			am4core.color("#C30052"),
			am4core.color("#9A0089"),
			am4core.color("#744DA9"),
			am4core.color("#0078D7"),

			

			];
		}
	}

	am4themes_darkTheme(target){

		if (target instanceof am4core.ColorSet) {
			return target.list = [

			am4core.color("#0B0C10"),
			am4core.color("#1F2833"),
			am4core.color("#C5C6C7"),
			am4core.color("#66FCF1"),
			am4core.color("#45A29E"),

			];
		}
	}

	am4themes_futuristicTheme(target){

		if (target instanceof am4core.ColorSet) {
			return target.list = [

			am4core.color("#2C3531"),
			am4core.color("#116466"),
			am4core.color("#D9B08C"),
			am4core.color("#FFCB9A"),
			am4core.color("#D1E8E2"),

			];
		}
	}

	getPieColorList(options){
		var color_list = [
			// Color from webqlo designer:
			// am4core.color("#67b7dc"),
			// am4core.color("#6794dc"),
			// am4core.color("#6771dc"),
			// am4core.color("#8067dc"),
			// am4core.color("#a367dc"),
			// am4core.color("#c767dc"),
			// am4core.color("#dc67ce"),
			// am4core.color("#dc67ab"),
			// am4core.color("#dc6788"),
			// am4core.color("#dc6967"),
			// Contrast color refer flattastic-color-palette by erigon https://www.pinterest.com/pin/172966441914141897/
			/*
			am4core.color("#4A89DC"),
			am4core.color("#967ADC"),
			am4core.color("#37BC9B"),
			am4core.color("#F6BB42"),
			am4core.color("#E9573F"),
			am4core.color("#D770AD"),
			am4core.color("#DA4453"),
			am4core.color("#434A54"), 

			am4core.color("#0B0C10"),
			am4core.color("#1F2833"),
			am4core.color("#C5C6C7"),
			am4core.color("#66FCF1"),
			am4core.color("#45A29E"),
			am4core.color("#8764B8"),
			am4core.color("#0078D7"),
			am4core.color("#2D7D9A"),
			am4core.color("#00B294"),
			am4core.color("#10893E"),
			am4core.color("#FF8C00"),
			am4core.color("#DA3B01"),
			am4core.color("#C30052"),
			am4core.color("#9A0089"),
			am4core.color("#744DA9"),
			am4core.color("#8E8CD8"),
			*/
			am4core.color("#469990"),
			am4core.color("#000075"),
			am4core.color("#e6194B"),
			am4core.color("#800000"),
			am4core.color("#9A6324"),
			am4core.color("#808000"),
			am4core.color("#000000"),
			am4core.color("#f58231"),
			am4core.color("#ffe119"),
			am4core.color("#bfef45"),
			am4core.color("#f032e6"),
			am4core.color("#42d4f4"),
			am4core.color("#4363d8"),
			am4core.color("#a9a9a9"),
			am4core.color("#3cb44b"),
			am4core.color("#fabed4"),
			am4core.color("#ffd8b1"),
			am4core.color("#fffac8"),
			am4core.color("#aaffc3"),
			am4core.color("#dcbeff"),

		];

		// if(_.get(options, 'use_sentiment_color_list', false) == true){
		// 	color_list =[
		// 		am4core.color("#5797de"),
		// 		am4core.color("#37e07f"),
		// 		am4core.color("#6435c1"),
		// 		am4core.color("#f5587d")
		// 	];
		// }
		return color_list;
	}

	getThemeColor(){
		var theme_color = {};
		switch(this.selectedTheme){
			case 'theme-dark':
			var color = ['#FFFFFF','#f6f6f6','#06f4f4','#0047cc','#000'];
			theme_color['label'] = color[0];
			theme_color['scrollbar'] = color[0];
			theme_color['min'] = color[1]; 
			theme_color['max'] = color[2]; 
			theme_color['hover'] = color[3];
			theme_color['heatmap_stroke'] = color[4];
			theme_color['range_stroke'] = color[0];
			theme_color['range_background'] = color[4];
			break;
			default:
			var color = ['#000000','#d6d6d6','#918ced','#5a22b2','#542c8a','#fff'];
			theme_color['label'] = color[0];
			theme_color['scrollbar'] = color[1];
			theme_color['min'] = color[2]; 
			theme_color['max'] = color[3]; 
			theme_color['hover'] = color[4];
			theme_color['heatmap_stroke'] = color[5];
			theme_color['range_stroke'] = color[0];
			theme_color['range_background'] = color[5]; 
		}
		return theme_color;
	}

	capitalizeFirstLetter(data){
		return  _.each(data,(x)=>{
			if(_.has(x, 'name')){
				x.name = _.join(_.map(x.name.replace("_", " ").split(" "), (value, key)=>{
					return value.charAt(0).toUpperCase() + value.slice(1);
				}), " ");
				if(!_.has(x,'id')){
					x.id = x.name.toLowerCase();
				}
				
			}
		});
	}

	setSliceColor(slice,color,is_active){
		slice.fill = color;
		slice.isShowing = is_active;
		slice.isActive = is_active;
		slice.isDown = is_active;
	}

	setSliceOriginalColor(slice){    
		slice.fill.original_color = slice.fill.hex;
		slice.dataItem.dataContext.originalColor = slice.fill.hex;            
	}

	onChartClick(series_template, options, name){
		if(options.onClick != null){    
			var field_name = options.fields.main[0].attribute;
			
			series_template.slices.template.events.on("hit", (x)=> {
				if(_.isEmpty(name)){
					this.filterChart(x,x.target.dataItem.dataContext,field_name,options,x.target.dataItem.valueY);
				}else{
					this.filterChart(x,x.target.dataItem.dataContext[name],name,options,x.target.dataItem.valueY);
				}
				


			}, this);
		}
	}

	onTreemapClick(series_template, treemap_level, options){ //BBB
		if(options.onClick != null){    
			// var field_name = options.fields.main[0].name;
			series_template.columns.template.events.on('hit', (x)=>{
				this.filterChart(x.target.dataItem.dataContext.dataContext,'',x.target.dataItem.dataContext.dataContext.name,options,x.target.dataItem.value);
			});
		}
	}

	onBarChartClick(series_template,options){
		if(options.onClick != null){    
			var field_name = options.fields.main[0].name;
			series_template.columns.template.events.on("hit", (x)=> {
				this.filterChart(x.target.dataItem.dataContext,x.target.dataItem.dataContext[field_name],field_name,options,x.target.dataItem.valueY, x.target.dataItem.component.name);
			}, this);
		}
	}

	onLineChartClick(series_template,options){
		if(options.onClick != null){    
			var field_name = options.fields.main[0].name;
			series_template.events.on("hit", (x)=> {
				this.filterChart(x.target.dataItem.dataContext,x.target.dataItem.dataContext[field_name],field_name,options,x.target.dataItem.valueY);
			}, this);
		}
	}

	legendClick(chart,options){
		chart.legend.itemContainers.template.events.on("hit",  (x)=>  {   
			var field_name = options.fields.main[0].name; 
			this.filterChart(x,x.target.dataItem.dataContext.dataContext[field_name],field_name,options,x.target.dataItem.valueY);
		});
	}

	saveImage(chart) {
		Promise.all([
			chart.exporting.getImage("png"),
		]).then(function(res) { 

			var binaryData = [];
			binaryData.push(res[1]);

			const a = document.createElement('a');
			a.href = res[1];
			a.setAttribute("download","screenshot");
			document.body.appendChild(a);
			a.click();
		});
	}

	filterChart(event,data,field_name,options,valueY, column_name=""){
		var filter = {},
		is_show = true;
		_.map(event,(value,key) => {
			filter[key] = value;
		});
		if(!_.isEmpty(column_name)){
			filter['column_name'] = column_name;
		}
		filter['field'] = options.filter_field;
		filter['data'] = {};
		filter['selected_data'] = {};
		filter['value'] = data;
		filter['show'] = true;
		filter['valueY'] = valueY;
		filter['options'] = options;

		filter['reset'] = false;
		filter['data_field'] = field_name;
		if(!_.isEmpty(options.filter_data)){            
			_.map(options.filter_data,(value,key)=>{             
				if(data == value){
					filter['show'] = false;
				}else{
					filter['show'] = true;
					filter['reset'] = true;
				}
				filter['data'][options.filter_field] = data;
			});
		}else{
			filter['data'][options.filter_field] = data;
			filter['selected_data'] = event;

		} 

		if(event.hasOwnProperty('is_clickable')){
			if(event.is_clickable === false){
				return;
			}else{
				options.onClick(filter);
			}
		}else{
			options.onClick(filter);
		}  
	}

	ngOnDestroy() {
	}
}