import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { ActivatedRoute, RoutesRecognized, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
// import { AlertDialogComponent } from 'app/shared/dialog/alert-dialog/alert-dialog.component';
import { DateRange } from 'model/daterange';
import { RouteParams } from 'model/route-params';
// import { Page } from 'model/pages';
import { DataService } from 'service/data';
import { environment } from 'environments/environment';
import { AlertService } from 'service/alert.service';
import { OrderAnalysis } from 'model/order-analysis'; 
import { Page } from 'model/pages';
import * as _ from 'lodash';

import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

declare var moment: any;
declare var $: any;

@Component({
    selector: 'share-daterange',
    templateUrl: './daterange.component.html',
    styleUrls: ['./daterange.component.scss']
})
export class ShareDaterangeComponent implements OnInit, OnChanges {

    @Input('options') options;
    @Input() layout;
    // @Input("selectedMode") selected_type:any;
    // @Input("minview") minview:any;
    // @Input("maxview") maxview:any;
    dateInputModel: any = {};
    platform: string;
    dateRangeOption: string;
    dateRangeOptionsObj: any = ['30', '90', '365'];
    dateRangeObj: any = [];
    data: any = {};
    pageInfo;
    account: any = {};
    subscriptions: any = [];
    dateEnabled: boolean = false;
    breadcrumbEnabled: boolean = true;
    pagesDBID: any = {};
    pageTitle: string;
    watchlist_data: any = {};
    duration : any = "";
    max_period: any = 86400000;
    date_format : any = {
        format: 'M dd,  yyyy',
        orientation: 'bottom',
        endDate: '+0m',
        autoclose: true,
        disableTouchKeyboard: true,
        maxViewMode: 1,
        // immediateUpdates : true,
        templates: {
            leftArrow: '&#60;',
            rightArrow: '&#62;'
        } 
    };
    opts:any = {
        nested: false,
        future: 0,
        max_date: -1,
    };
    active_element: any = '';
    constructor(
        private dataService: DataService,
        private alertService: AlertService,
        private routeParams: RouteParams,
        private dateRange: DateRange,
        private router: Router,
        // private pageModel: Page,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private orderAnalysisModel: OrderAnalysis,
        private pageModel: Page
        ) {



        // // get dateinput
        this.subscriptions.push(this.dateRange.subscribe(value => {
            this.dateInputModel = value;
        }));
        this.dateInputModel = this.dateRange.data;

        this.subscriptions.push(this.pageModel.subscribe(value => {
            this.account = value.account;
        }));

        // this.subscriptions.push(this.orderAnalysisModel.subscribe(value => {
            //     this.destroyDate();
            //     this.duration = this.dataService.get("duration");
            //     this.setDate();
            // }));

            // this.dateRangeOptions = ['custom_date','last_30_days', 'last_quarter', 'last_year'];
            this.dateRangeOption = this.dataService.get('dateRangeOption', this.dateRangeObj[0]);
            


        }

        getMonthDateRange(year, month) {
            // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
            // array is 'year', 'month', 'day', etc
            var startDate = moment([year, month - 1]).format("YYYY-MM-DD");

            // Clone the value before .endOf()
            var endDate = moment(startDate).endOf('month').format("YYYY-MM-DD");

            // just for demonstration:
            // console.log('getStart',startDate);
            // console.log('getEnd',endDate);

            // make sure to call toDate() for plain JavaScript date type
            return { start: startDate, end: endDate };
        }

        convertData() {
            this.dateEnabled = this.routeParams.data.dateEnabled;
            this.breadcrumbEnabled = this.data.breadcrumbEnabled;
            if (this.data.preprocess == true) {
                this.pageInfo = _.cloneDeep(this.data);
                this.platform = this.routeParams.data.platform;

                if (!_.isEmpty(this.platform)) {
                    this.pageInfo.urls = _.chain(this.pageInfo.urls).map((x: any) => {
                        if (_.isEmpty(x.title)) {
                            var prefix = x.prefix || 'title';
                            var title = prefix + '_' + this.platform;
                            if (!_.isEmpty(x.suffix)) {
                                title += '_' + x.suffix;
                            }
                            x.title = title;
                            return x;
                        }
                        return x;
                    }).value();
                }
            }
            else {
                this.pageInfo = this.data;
            }
        }

        ngOnInit() {
            this.ngOnChanges();
        }

        ngOnChanges() {
            _.merge(this.opts, this.options);
            if(this.opts.nested){ // nested dateInput do not need to save
                this.dateInputModel.save = false;
            }
            this.setDate();
        }

        getDuration(startDate, endDate) {
            var dateFormat = "YYYY-MM-DD";
            return [moment(startDate).format(dateFormat), moment(endDate).format(dateFormat)];
        }


        setDate() {
            // dateRangeOptions
            this.dateRangeObj = this.getDuration(this.dateInputModel.start, this.dateInputModel.end);
            //         $('.start-date').datepicker('setDate', new Date(2019, 3, 1));
            // $('.start-date').datepicker('update');    
            // console.log('dateInput Split',this.dateRangeObj);
            this.getMonthDateRange(2019,6);
            var startDate = this.dateRangeObj[0].split('-'),
                endDate = this.dateRangeObj[1].split('-'); 

            


            if(this.duration == 'monthly' || this.duration == 'weekly'){
                endDate = this.getMonthLastDate().split('-');
            }


            this.preSelectDate('.start-date', 'start', startDate);
            this.preSelectDate('.end-date', 'end', endDate);

            return false;

        }

        getMonthLastDate(){
            var date    = new Date(this.dateRangeObj[1]);
            var last_day = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            var year_month = last_day.getFullYear() + '-'+ (this.processMonthDate(last_day.getMonth() + 1))+ '-' ;

            var end_date =  year_month+(this.processMonthDate(last_day.getDate()));

            return end_date;
        }

        processMonthDate(date){
          var convert_date;

          convert_date = date < 10 ? '0' + date : date;

          return convert_date;

      }

      preSelectDate(element, type, date) {
        // $('.start-date').datepicker('destroy');
        // $('.'+element+'-date').datepicker('destroy');

        if(this.duration == 'monthly' || this.duration == 'weekly'){
            this.date_format = {
                format: 'M ,yyyy',
                orientation: 'auto bottom',
                endDate: '+0m',
                autoclose: true,
                disableTouchKeyboard: true,
                maxViewMode: 2,
                minViewMode: 1,
                // immediateUpdates : true,
                templates: {
                    leftArrow: '&#60;',
                    rightArrow: '&#62;'
                }
            }
        }else{
            this.date_format = {
                format: 'M dd,  yyyy',
                orientation: 'auto bottom',
                endDate: '+0m',
                autoclose: true,
                disableTouchKeyboard: true,
                maxViewMode: 2,
                // immediateUpdates : true,
                templates: {
                    leftArrow: '&#60;',
                    rightArrow: '&#62;'
                } 
            }
        }
        if(this.opts.nested){
            this.date_format.endDate = null;
            if(this.opts.future > 0){
                this.date_format.endDate = `+${this.opts.future}d`
            }
        }
        var location = window.location.pathname.split("/");
        this.date_format["startDate"] = new Date("2018-01-01");//set min date to 2018
        // console.log("pathname",location[location.length - 2]);
        $(element).datepicker(this.date_format);
        $(element).datepicker('setDate', new Date(date[0], parseInt(date[1]) - 1, date[2]))
        .on('show', (e) => {
            // console.log("show: ", element);    
            if(location[location.length - 2] != 'demo-food'){
                this.appendDaysFilter(type);
            }
            
            $(element).addClass('active');
            $(element).closest('li').addClass('active');
            e.stopPropagation();
            e.preventDefault();
            return false;
        }).on('changeDate', (e) => {
            console.log("changeDate: ", element,e.target.value);
            $(element).val(e.target.value);
            this.selectedDate(e, type);
            this.hideDate(element);
        })
        .on('hide', (e) => {
            // console.log("hide: ", element);
            $(element).removeClass('active');
            $(element).closest('li').removeClass('active');
        })
        .on('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
        });
    }

    hideDate(element) {
        $(element).datepicker('hide');
        this.active_element = '';
    }

    showDatePicker(element) {
        $(element).datepicker('show');
        this.active_element = element;
    }

    destroyDate() {
        $('.start-date').datepicker('destroy');
        $('.end-date').datepicker('destroy');

        this.turnOffDatePickerEventListener('.start-date');
        this.turnOffDatePickerEventListener('.end-date');
    }

    turnOffDatePickerEventListener(element) {
        $(element).off("show")
        .off("changeDate")
        .off("hide")
        .off("click");
    }

    appendDaysFilter(rangeType) {    
        var dateRange = this.dateRangeOptionsObj;
        if (!$('.day-filter').length) {
            var setDaysItem = '',
            active = '',
            setDays = '';

            _.forEach(dateRange, (value) => {
                active = '';
                if (this.dateInputModel.daysRange == value) {
                    active = 'active';
                }

                setDaysItem += '<li class="col-4 text-center day-item-filter ' + active + ' ' + value + '-filter' + '" data-range-type="' + rangeType + '" data-days="' + value + '" >' + value + ' DAYS </li>';
            });
            // onclick="setDays('+value+')"
            setDays = '<ul class="row day-filter m-0">' + setDaysItem + '</ul>';
            $(".datepicker-days").prepend(setDays);
        }

        $('.day-item-filter').on('click', (e) => {
            var days = $(e.target).data('days');
            $('.day-item-filter').removeClass('active');
            $('.' + days + '-filter').addClass('active');
            this.hideDate('.' + $(e.target).data('range-type') + '-date');
            this.destroyDate();
            // $('.'+$(e.target).data('range-type')+'-date').datepicker('destroy');
            // this.destroyDate('.'+$(e.target).data('range-type')+'-date');
            // this.initDate('.'+$(e.target).data('range-type')+'-date');
            this.setDays($(e.target).data('range-type'), $(e.target).data('days'));
        });
    }


    setDays(rangeType, value) {
        var start, end;
        switch (value) {
            case 90: {
                // var lastQuarter = moment().subtract(3, 'months').quarter();
                // start = moment().subtract(3, 'months').quarter(lastQuarter).startOf('quarter');
                // end = moment().subtract(3, 'months').quarter(lastQuarter).endOf('quarter');;

                start = moment().subtract(91, 'days');
                end = moment().subtract(1, 'days');
                break;
            }
            case 365: {
                start = moment().subtract(366, 'days');
                end = moment().subtract(1, 'days');
                break;
            }
            case 30: { // last_30_days
                start = moment().subtract(31, 'days');
                end = moment().subtract(1, 'days'); // yesterday
                break;
            }
        }

        var convertedDateRange = this.getDuration(start, end);
        this.preSelectDate('.start-date', 'start', convertedDateRange[0].split('-'));
        this.preSelectDate('.end-date', 'end', convertedDateRange[1].split('-'));

        if (start && end) {
            this.dateInputModel.start = start;
            this.dateInputModel.end = end;
            this.dateInputModel.daysRange = value;
            this.dateRange.data = this.dateInputModel;
        }
        this.dataService.set('dateRangeOption', value);
    }

    selectedDate(e, option) {
        console.log("selecetd date", e.target.value);
        var convertToIsoDate = new Date(e.target.value).toISOString(),
        dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
        convertToDate = moment(convertToIsoDate).format(dateFormat),
        convertedDate = moment(convertToDate);
        var duration = 0;
        this.dateInputModel.daysRange = '';
        $('.day-item-filter').removeClass('active');
        if (option == 'start') {
            duration = Math.ceil((this.dateInputModel.end - convertedDate) / this.max_period);
        }
        else {
            duration = Math.ceil((convertedDate - this.dateInputModel.start) / this.max_period);
        }
        
         if (duration > 0) {
            if (option == 'start') {
                this.dateInputModel.start = convertedDate;
            }
            else {
                this.dateInputModel.end = convertedDate;
            }
        }
        else {
            this.dateInputModel.start = convertedDate;
            this.dateInputModel.end = convertedDate;

        }
        this.dateRange.data = this.dateInputModel;

        this.dataService.set('dateRangeOption', '');
    }


    ngOnDestroy() {
        // console.log(this.subscriptions)
        _.map(this.subscriptions, (x: any) => { x.unsubscribe(); });
    }


}
