import { Injectable, OnDestroy} from "@angular/core";
import { ConfirmService } from 'service/confirm.service';

@Injectable()
export class ExternalLinkService implements OnDestroy {
  constructor(
    private confirmService: ConfirmService,
  ) { }

  public open(link, event) {
    var dialog = this.confirmService.show({
      'title': 'information',
      'message': 'You will be redirect to ' + link.split("/", 3)[2] + ', \nclick on the “confirm” button below to proceed.',
      'confirm_button': 'confirm',
      'confirm_link': link, // if pass in the uri, will open in new tab
    });
    var subscription = dialog.subscribe((result) => {
      subscription.unsubscribe();
    });
    event.preventDefault();
    return false;
  }
  ngOnDestroy() {
  }
}
