import { Component, OnInit, Input, HostListener } from '@angular/core';
import { RouteParams } from 'model/route-params';
import { Router, Event, NavigationEnd }    from '@angular/router';

import * as _ from 'lodash';

@Component({
	selector: 'share-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class ShareBreadcrumbComponent implements OnInit {
	@Input('options') input_options: any;
	options: any;

	subscriptions: any = [];
	page_model: any;
	bShow : boolean = true;

	page_info: any = {
		id: '',
		platform: '',
		url: '',
		title:'',
	};
	page_data: any;

	title: string;
	tooltip : string;
	is_sub_path: any = false;
	is_centered_large_title: boolean = false;

	constructor(
		private router: Router,
		public routeParams: RouteParams, ) {

		this.subscriptions.push(this.routeParams.subscribe(params => {
			this.page_info.id = params.id;
			this.page_info.platform = params.platform;
			this.page_info.url = params.url;
			this.page_info.title = params.title;
			if(_.has(params,'title')){
				if(_.has(params.title,'tooltip')){
					this.tooltip = params.title.tooltip
				}else{
					this.tooltip = '';
				}
			}	

			this.checkIsCenteredLargeTitle(this.page_info);
			this.loadContent();
		}));

	}

	ngOnInit() {
		this.loadContent();
	}

	ngOnChanges() {
		this.loadContent();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.checkIsCenteredLargeTitle(this.page_info);
	}

	ngOnDestroy() {
		_.map(this.subscriptions, (x: any) => { x.unsubscribe(); });
	}

	checkIsCenteredLargeTitle(_params) {
		this.is_centered_large_title = false;

		if(_.has(_params, 'title.title_id') ){
			if(_.get(_params, 'title.title_id', '') == 'dashboard') {
				if(window.innerWidth > 768) {
					this.is_centered_large_title = true;
				}
			}
		}
	}

	checkOption() {
		this.bShow = true;
		this.options = {
			is_enable_header: true,
			is_enable_date_range: false
		};

		if (this.input_options) {
			_.merge(this.options, this.input_options);
		}
	}

	loadContent() {
		if (this.page_info.url) {
			this.checkOption();
			this.title = this.getTitle();
			// this.getPageData();
		}
	}

	getTitle() {
		if (this.page_info.title){
			this.is_sub_path = _.get(this.page_info.title, 'sub_path', false);
			if( _.get(this.page_info.title, 'breadcrumb', true) ){
				return this.page_info.title.name;
			}
		}
		return null;
	}

	getPageData() {
		// if (!this.is_sub_path || _.isEmpty(this.page_model)) {
		// 	return;
		// }

		// this.page_data = _.find(this.page_model.all, (page) => {
		// 	return page.id == this.page_info.id;
		// });
	}
}
