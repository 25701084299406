import { Injectable, OnDestroy} from "@angular/core";
import { Http, Response, ResponseContentType } from '@angular/http';
import { Router,ActivatedRoute }    from '@angular/router';
import { RouteParams } from 'model/route-params';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AlertService } from 'service/alert.service';
import { ThemeService } from 'service/theme';
import { HttpHelper } from 'service/http';
import { DataService } from 'service/data';
import { HttpClient, HttpHeaders   } from '@angular/common/http';
import { TOKEN_NAME,AuthService  } from 'service/auth.service';
import * as _ from 'lodash'; 
declare var $:any;
declare var moment: any;

@Injectable()
export class PrescriptionDispensed implements OnDestroy{
    private subject = new Subject<any>();
    private subscriptions = null;

    private _data :any = {};

    private default_link : any  = {
        "name" :"",
        "id" : "", 
        "data" : [], 
        "is_clickable":true,
        "link": "", 
        "params":{}
    }

    constructor(
        private http: Http,
        private httpHelper: HttpHelper,
        private themeService:ThemeService,
        private authService: AuthService,
        public routeParams: RouteParams,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        ){
    }
    get data():any{
        return this._data;
    }
    set data( data: any){
        var temp = Object.assign({}, data);
        this._data = temp;
        this.subject.next(this._data);
    }

    getItemDetail(param,callback=null){
        var get_item_detail = {};
        get_item_detail["item_desc"] = param.item_desc;
        get_item_detail["item_category_desc"] = param.item_category_desc;
        get_item_detail["sub_group_desc"] = param.sub_group_desc;
        get_item_detail["drug_nondrug_code"] = param.drug_nondrug_code;
        this.getApiData('phis/stock/search',get_item_detail,callback);
    }

    getApiData(api, api_params,callback=null){
        // api_params["version"] = environment.api_version;
        if(environment.production){
            api_params["version"] = environment.api_version;
        }else{
            api_params["version"] = 5;
        }
        this.http
        .post(`${environment.server.apiUrl}/`+api, api_params)
        .toPromise()
        .then(res => {
            if(callback){
                callback(res.json().response, api_params);
            }
            this.data = res.json().response;
        })
        .catch(res => {
            console.log('Error:',res);
        });
    }

    getFilterOptions(api,api_params,callback=null){
        if(environment.production){
            api_params["version"] = environment.api_version;
        }else{
            api_params["version"] = 5;
        }
        this.http
        .post(`${environment.server.apiUrl}/`+api, api_params)
        .toPromise()
        .then(res => {
            if(callback){
                callback(res.json().response, api_params);
            }
            this.data = res.json().response;
        })
        .catch(res => {
            console.log('Error:',res);
        });
    }

    getReportData(api, api_params,callback=null){
        // api_filter["test"] = true;
        api_params["version"] = environment.api_version;
        const options = {

            // Ignore this part or  if you want full response you have 
            // to explicitly give as 'boby'as http client by default give res.json()
            observe:'response' as 'body',

            // have to explicitly give as 'blob' or 'json'
            responseType: 'text' as 'json' 
        };
        let token = localStorage.getItem(TOKEN_NAME);
        this.http
        .post(`${environment.server.apiUrl}/`+api, api_params, {responseType:ResponseContentType.Blob})
        .toPromise()
        .then(res => {
            var token = res.headers.get('Authorization').split(' ')[1];
            this.setNewToken(token);
            if(callback){
                callback(res, api_params);
            }
            // this.data = JSON.stringify(res);
        })
        .catch(res => {
            console.log('Error:',res);
        });
    }

    setChartData(api_data,data,parent_field_name,data_type,data_field="data"){

        _.map(api_data.data[parent_field_name][data_type],(value,key) => {
            value["type"] = data_type;
            value["api_field_name"] = data[data_type]["api_field_name"];
            value["transaction_type"] = data[data_type]["transaction_type"];
        })
        data[data_type][data_field] = api_data.data[parent_field_name][data_type];


        if(data[data_type]["active_chart"]["id"] == "side_column"){
           var process_data =  this.processIntoSideColumnData(api_data.data[parent_field_name][data_type], data[data_type]["sub_field"],data_type);
           data[data_type][data_field] = process_data;
        }
        return data[data_type][data_field];
    }



    processIntoSideColumnData(data,children_field,data_type){
        _.map(data,(value,key) =>{
            _.map(value[children_field],(data_val,data_key) => {
                value[data_val.id] = data_val.quantity;
             });
            value["type"] = data_type;
        });
        return data;
        
    }

    processIntoTreeMapData(api_data,data,parent_field_name,data_type){

        var filter_data = {};
        var process_data = {};
        var final_process_data = [];

        _.map(api_data.data[parent_field_name][data_type],(value,key) => {
            filter_data[value.id] = {};
            filter_data[value.id]["data"] = [];
            _.map(value.drug_nondrug_code,(data_val,data_key) => {
                process_data = {};
                process_data["name"] = data_val.name;
                process_data["drug_nondrug_code"] = [];
                process_data["drug_nondrug_code"].push(data_val);
                filter_data[value.id]["data"].push(process_data);
            });

        });
        data[data_type]["data"] = filter_data;

    }

    processDataForComparisonChart(data,chart,field_name){

        _.map(data,(value,key) => {
            _.map(value[chart[field_name]["sub_field"]],(data_value,data_key) => {
                value[data_value.id] = 0;
                value[data_value.id] = data_value["purchase_amount"];
            })
        })

        return data;
    }

    setLinkInData(data,link,params,view_type){
        var default_link = {
            "name" : "",
            "id" : "",
            "data" : [], 
            "is_clickable":true,
            "link": "",
            "params": []
        }

        _.map(data,(value,key) =>{
            default_link
        })

    }

    setTableData(data,link,params,current_layer,next_layer, is_set_link=false, is_clickable=true){
        
       _.map(data,(value,key) => {
           var query_params = Object.assign({},params);

           var default_link = {
            "name" : value["name"],
            "id" : value["id"], 
            "is_clickable":is_clickable,
            "link": "", 
            "params":query_params
          }

          var keys = _.keys(value.summary.detail);
          var next_level = value.summary.detail[keys[0]][0].next_layer.id;

          if(is_set_link){
              if(next_level == 'individual'){
                  default_link["link"] = link+"/"+next_level+"/"+value["id"];
              }else{
                  default_link["link"] = link+"/"+next_level+"/"+value["id"];
              }
          }
          
           _.map(value.summary.overall,(overall_data,overall_key) => {
               var active_type = "";
               _.map(value.summary.detail[overall_data.id],(detail_data,detail_key) => {
                   
                   if(active_type != detail_data.transaction_type_code){
                       detail_data["is_show"] = true;
                   }else{
                       detail_data["facility_group"] = "";
                       detail_data["requester_group_name"] = "";
                       detail_data["transaction_type_name"] = "";
                   }
                   query_params["selected_"+detail_data.next_layer.id] = detail_data.next_layer.value;
                   query_params[detail_data.next_layer.id+"_name"] = detail_data.next_layer.name;
                    if(detail_data.next_layer.id == "state"){
                       query_params["state"] = [detail_data.next_layer.value];
                    }else if(detail_data.next_layer.id == "individual_institute"){
                       query_params["individual"] = [detail_data.next_layer.value];
                    }
                   
                   active_type = detail_data["transaction_type_code"];


               })
           });
           value = _.merge(value,default_link);
       });

        return data;
    }


    setDrugData(api_data,link,params,view_type,has_next_layer){
        var get_link = this.default_link;
        get_link["is_clickable"] = has_next_layer;
        _.map(api_data,(value,key) => {
            value["data"] = [];
            var query_params = Object.assign({},params);
            if(view_type =="state"){
                query_params["selected_state"] = value["id"];
                query_params["state_name"] =value["name"];
            }else if(view_type =="district"){
                query_params["selected_district"] = value["id"];
                query_params["district_name"] = value["name"];
            }
            get_link["name"]  = value["name"];
            get_link["id"]  = value["id"];
            get_link["params"]  = query_params;

            if(!_.isEmpty(link)){
                get_link["link"] = link+"/"+view_type+"/"+value["id"]; 
            }

            value = _.merge(value,get_link);

            _.map(value.summary.detail,(data_val,data_key) => {
                _.map(data_val,(data_value,data_key) => {
                    value["data"].push(data_value);
                })
            });
        });
        return api_data;
    }

    setYearInTitle(data,field_name,year){
        var title = data.title.split(" ");
        if(title[title.length-1] == "" || title[title.length-1] == "Year"){
            data.title = data.title+" "+year;
            data.desc = data.desc+" "+year;
            if(field_name == "procurement_by_state_summary" || field_name == "procurement_by_state_item_group" || field_name == "procurement_by_state_po_type" ){
                data.title = data.title+" - By State";
                data.desc = data.desc+" - By State";
            }else if(field_name == "procurement_by_district_summary"|| field_name == "procurement_by_district_po_type" || field_name =="procurement_by_district_item_group"){
                data.title = data.title+" - By District";
                data.desc = data.desc+" - By District";
            }

        }


        return data;
    }

    onDrugCodeClick(data,params){
        var query = Object.assign({},params);
        var default_link = "/procurement/list/overall/drug";
        query["drug_nondrug_code"] = [];
        query["drug_nondrug_code"].push(data.id);

        params = query;

        if(query.hasOwnProperty("selected_state")){
            default_link = default_link+"/state/"+query["selected_state"];
        }

        if(query.hasOwnProperty("selected_district")){
            default_link = default_link+"/district/"+query["selected_district"];
        }

        this.router.navigate([default_link], { relativeTo: this.activatedRoute, queryParams: query});
    }

    setNewToken(token){
        this.authService.setToken(token);
    }




    subscribe(callback){
        var subscription = this.subject.subscribe(callback);
        if(!_.isEmpty(this._data)){
            callback(this._data);
        }

        return subscription;
    }
    ngOnDestroy() {
        _.map(this.subscriptions, (x: any) => { x.unsubscribe(); });
    }
}
