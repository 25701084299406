
import { CommonModule } from '@angular/common';
import { NgModule, ErrorHandler } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Http, HttpModule, RequestOptions } from '@angular/http';
import { Routes, RouterModule } from '@angular/router';
// import { BrowserModule } from '@angular/platform-browser';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ImgFallbackModule } from 'ngx-img-fallback';
// import { FacebookModule } from 'ngx-facebook';
import { MapIterate } from 'pipe/mapiterate.pipe';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { NgArrayPipesModule, NgStringPipesModule } from 'angular-pipes';
import { SidebarModule } from 'ng-sidebar';
// import { Angulartics2Module } from 'angulartics2';
// import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

/** unchecked plugin*/
/*import { Daterangepicker } from 'ng2-daterangepicker';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { NgsRevealModule } from 'ng-scrollreveal';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShareButtonsModule } from '@ngx-share/buttons';*/

// Translate
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


/** custom shared component */
import { DialogModule } from './dialog/dialog.module';
import { ScriptHackComponent } from './script-hack/script-hack.component';
import { FooterComponent } from './footer/footer.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { NewAuthComponent } from './new-auth/new-auth.component';
import { ShareCardLoadingComponent } from './card-loading/card-loading.component';
import { ShareInsightNavigatorComponent } from './insight-navigator/insight-navigator.component';
import { ShareBreadcrumbComponent } from './breadcrumb/breadcrumb.component';
// import { AppComponent } from './app.component';


// Service
import { ExtendedHttpService } from 'service/extended-http.service';
import { AlertService } from 'service/alert.service';
import { ConfirmService } from 'service/confirm.service';
import { ExternalLinkService } from 'service/external-link.service';
import { AuthErrorHandler } from './../errors/auth-error-handlers';
import { ConvertPipe } from 'pipe/convert.pipe';
import { ThousandPipe  } from 'pipe/thousand.pipe';
import { QuickTipComponent } from './quick-tip/quick-tip.component';
import { ShareDaterangeComponent } from './daterange/daterange.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { ProfileOfflineComponent } from './profile-offline/profile-offline.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FilterItemSidebarComponent } from './filter-item-sidebar/filter-item-sidebar.component';
import { PhisTableComponent } from './phis-table/phis-table.component';
import { SingleCalendarComponent } from './single-calendar/single-calendar.component';
import { IssueListCalendarComponent } from './issue-list-calendar/issue-list-calendar.component';
import { CardViewComponent } from './card-view/card-view.component';
import { IssueListBreakdownComponent } from './issue-list-breakdown/issue-list-breakdown.component';
import { IssueListCardViewComponent } from './issue-list-card-view/issue-list-card-view.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { DynamicCardViewComponent } from './dynamic-card-view/dynamic-card-view.component';
import { DataStatsComponent } from './data-stats/data-stats.component';
import { DynamicCardViewTableComponent } from './dynamic-card-view-table/dynamic-card-view-table.component';
import { DynamicCardViewNoCollapseComponent } from './dynamic-card-view-no-collapse/dynamic-card-view-no-collapse.component';
import { DynamicCardViewSearchComponent } from './dynamic-card-view-search/dynamic-card-view-search.component';
import { BarLoadingComponent } from './bar-loading/bar-loading.component';
import { OverlayLoadComponent } from './overlay-load/overlay-load.component';
import { AlertComponent } from './alert/alert.component';
import { DaterangeOptionsComponent } from './daterange-options/daterange-options.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { LogisticDropdownComponent } from './logistic-dropdown/logistic-dropdown.component';


@NgModule({
  declarations: [
    FooterComponent,
    LeftSidebarComponent,

    ScriptHackComponent,
    // LayoutUserComponent,
    NewAuthComponent,
    MapIterate,
    ConvertPipe,
    ThousandPipe,
    QuickTipComponent,
    ShareCardLoadingComponent,
    ShareInsightNavigatorComponent,
    ShareDaterangeComponent,
    ShareBreadcrumbComponent,
    SectionTitleComponent,
    ProfileOfflineComponent,
    NavigationComponent,
    FilterItemSidebarComponent,
    PhisTableComponent,
    SingleCalendarComponent,
    IssueListCalendarComponent,
    CardViewComponent,
    IssueListBreakdownComponent,
    IssueListCardViewComponent,
    DynamicTableComponent,
    DynamicCardViewComponent,
    DataStatsComponent,
    DynamicCardViewTableComponent,
    DynamicCardViewNoCollapseComponent,
    DynamicCardViewSearchComponent,
    BarLoadingComponent,
    OverlayLoadComponent,
    AlertComponent,
    DaterangeOptionsComponent,
    CustomTableComponent,
    LogisticDropdownComponent
  ],
  providers:[
    {
      provide: Http,
      useClass:ExtendedHttpService
    },
    {
      provide: ErrorHandler,
      useClass: AuthErrorHandler
    },
    AlertService,
    ConfirmService,
    ExternalLinkService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    HttpModule,
    HttpClientModule,
    ImgFallbackModule,
    RouterModule,
    TranslateModule,
    DialogModule,
    // FacebookModule.forRoot(),
    Nl2BrPipeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSliderModule,
    NgArrayPipesModule,
    NgStringPipesModule,
    SidebarModule.forRoot(),
  ],
  exports:[
  	CommonModule,
    FormsModule,
    NgSelectModule,
  	ImgFallbackModule,
    ConvertPipe,
    ThousandPipe,
    MapIterate,
  	HttpModule,
  	HttpClientModule,
    RouterModule,
    // FacebookModule,
    TranslateModule,
    Nl2BrPipeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSliderModule,
    NgArrayPipesModule,
    NgStringPipesModule,
    SidebarModule,

    DialogModule,
    ScriptHackComponent,
    NewAuthComponent,
    QuickTipComponent,
    ShareCardLoadingComponent,
    LeftSidebarComponent,
    ShareInsightNavigatorComponent,
    ShareDaterangeComponent,
    ShareBreadcrumbComponent,
    SectionTitleComponent,
    ProfileOfflineComponent,
    FilterItemSidebarComponent,
    NavigationComponent,
    PhisTableComponent,
    SingleCalendarComponent,
    IssueListCalendarComponent,
    CardViewComponent,
    IssueListBreakdownComponent,
    IssueListCardViewComponent,
    DynamicTableComponent,
    DynamicCardViewComponent,
    DataStatsComponent,
    DynamicCardViewTableComponent,
    DynamicCardViewNoCollapseComponent,
    BarLoadingComponent,
    OverlayLoadComponent,
    AlertComponent,
    DaterangeOptionsComponent,
    CustomTableComponent,
    LogisticDropdownComponent
  ]
})
export class SharedModule { }
