import { Injectable, OnDestroy } from "@angular/core";
import { Http, Response } from '@angular/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { AlertService } from 'service/alert.service';
import { ThemeService } from 'service/theme';

declare var Chartist: any;
import * as _ from 'lodash';
declare var $: any;

@Injectable()
export class SvgHelper implements OnDestroy {
	public BAR_VERTICAL: string = 'bar-vertical';
	public BAR_HORIZONTAL: string = 'bar-horizontal';
	public LINE_CHART: string = 'line-chart';
	public IS_COLORFUL: boolean = true;
	private subject = new Subject<any>();
	private subscription = null;
	private loading: boolean = false;
	private _data: any = {
	};

	constructor(
	) {

	}

	createGradientSvg(_draw_object, type, id, is_colorful = true) {
		var _stops, defs, gradient, i, len, stop;

		if (type == 'pie_brand') {
			var colors = [
				{ start: '#5797de', end: '#2eefec' },
				{ start: '#70ec79', end: '#caff70' },
				{ start: '#6436c1', end: '#c486f3' },
				{ start: '#f5587d', end: '#f282ce' },
			];
		} else {
			var colors = [
				{ start: '#5797de', end: '#2eefec' },
				{ start: '#6435c1', end: '#c486f3' },
				{ start: '#f5587d', end: '#f282ce' },
			];	
		}
		
		var single_color = [
			{ offset: 0, stopColor: '#30d7fe' },
			{ offset: 100, stopColor: '#d16eff' }
		];
		var elements_options = {};
		// elements_options['id'] = 'gradient-' + type + '-' + _draw_object.index + '-' + id;
		elements_options['id'] = this.generateGradientId(type, _draw_object.index, id);

		if (type == this.BAR_VERTICAL) {
			elements_options['x1'] = "0%";
			elements_options['y1'] = "0%";
			elements_options['x2'] = "0%";
			elements_options['y2'] = "100%";
		}

		if (type == 'bar-horizontal') {
			elements_options['x1'] = "0%";
			elements_options['y1'] = "0%";
			elements_options['x2'] = "100%";
			elements_options['y2'] = "0%";
		}

		if (type == this.LINE_CHART) {
			elements_options['x1'] = "0%";
			elements_options['y1'] = "0%";
			elements_options['x2'] = "0%";
			elements_options['y2'] = "100%";
		}

		defs = new Chartist.Svg('defs');

		gradient = defs.elem('linearGradient', elements_options);

		if (colors[_draw_object.index]) {
			_stops = [
				{ offset: 0, stopColor: colors[_draw_object.index].start },
				{ offset: 100, stopColor: colors[_draw_object.index].end }
			];
		}

		if (type == this.LINE_CHART) {
			_stops = single_color;
		}
		else if ((type == this.BAR_VERTICAL || type == this.BAR_HORIZONTAL) && !is_colorful) {
			_stops = single_color;
		}
		else if (type == 'donut-chart' && !is_colorful) {
			_stops = single_color;
		}

		for (i = 0, len = _stops.length; i < len; i++) {
			stop = _stops[i];
			stop = gradient.elem('stop', { 'offset': stop.offset, 'stop-color': stop.stopColor });
		}
		_draw_object.group.append(defs, true);
	}

	generateGradientId(type, index, id) {
		return 'gradient-' + type + '-' + index + '-' + id;
	}

	generateTooltipId(id) {
		return 'tooltip-' + id;
	}

	getTooltipId(id) {
		return 'tooltip-' + id;
	}

	getGradientId(url, type, index, id) {
		return url + '#' + this.generateGradientId(type, index, id);
	}

	getRoundImageId(url, type, index, id) {
		return url + '#' + this.generateRoundImageId(type, index, id);
	}

	generateRoundImageId(type, index, id) {
		return 'round-image-' + type + '-' + index + '-' + id;
	}

	createRoundImageSvg(_draw_object, _type, _chart_id, _image_size, _image_x_pos, _image_y_pos) {
		var svg_defs, svg_clip_path, svg_clip_path_setting, svg_circle_setting, svg_circle;


		svg_defs = new Chartist.Svg('defs');

		svg_clip_path_setting = {
			id: this.generateRoundImageId(_type, _draw_object.index, _chart_id)
		};
		svg_clip_path 	= svg_defs.elem('clipPath', svg_clip_path_setting);

		svg_circle_setting = {
			r: _image_size / 2,
			cx: _image_x_pos + (_image_size / 2),
			cy: _image_y_pos + (_image_size / 2)
		}
		
		svg_circle		= svg_clip_path.elem('circle', svg_circle_setting);


		_draw_object.group.append(svg_defs, true);
	}

	createTooltipSvg(id){
		var tooltipId = this.generateTooltipId(id);
		return '<svg id="'+tooltipId+'" width="0" height="0"><path class="tooltip-line" stroke-width="1" stroke="#000000" d="M0 0 0 0" fill="none"/></svg>'
	}

	ngOnDestroy() {

	}
}
