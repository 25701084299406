import { Injectable, OnDestroy} from "@angular/core";
import { Http, Response } from '@angular/http';
import { Router }    from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { DateRange } from 'model/daterange';
import { AlertService } from 'service/alert.service';
import { ThemeService } from 'service/theme';
import { HttpHelper } from 'service/http';
import { DataService } from 'service/data';
import { CountryService } from 'service/country';

import * as _ from 'lodash'; 
declare var $:any;
declare var moment: any;

@Injectable()
export class OrderAnalysis implements OnDestroy{
	private subject = new Subject<any>();
	private subscription = null;
	private loading:boolean = false;
	private DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
	private _data :any = {
		// 'categories' : [],
		// 'active_category' : {},
	};

	selected_duration:string = "";
	dateInput: any;
	format:any;
	min:any;
	max:any;

	meta : any = {};

	api_filter : any = {
		'duration':"",
		"keys" : "sales", //,sales,menu,flavor,combo
		'filter_type':"",
		'filter_field': "",
		'start_date':"2019-06-01",
		'end_date':"2019-06-30",
		'sort':"desc",
		'limit':10
	};

	week_date :any = {
		"1" : {
			"start_date" : 1,
			"end_date" : 7,
		},
		"2" : {
			"start_date" : 8,
			"end_date" : 14,
		},
		"3" : {
			"start_date" : 15,
			"end_date" : 21,
		},
		"4" : {
			"start_date" : 22,
			"end_date" : 28,
		},
		"5" : {
			"start_date" : 29,
			"end_date" : 30,
		},
	};
	subscriptions: any = [];
	page_model_data:any;
	selected_country: string ;
	
	constructor(
		private alertService:AlertService,
		private http: Http,
		private httpHelper: HttpHelper,
		private themeService:ThemeService,
		private dataService: DataService,
		private countryService : CountryService,
		private dateRange: DateRange,
		){

		this.subscriptions.push(this.dateRange.subscribe(value => {
			this.dateInput = value;
			// this.ngOnChanges();
		}));

		this.subscriptions.push(this.countryService.subscribe((selectedCountry:any)=>{
			this.selected_country = selectedCountry;
		}));

	}
	get data():any{
		return this._data;
	}
	set data( data: any){
		var temp = Object.assign({}, data);
		this._data = temp;
		this.subject.next(this._data);
	}

	setDuration(duration){
		this.selected_duration = duration;
		this.dataService.set('duration', duration);
		this.api_filter['duration'] = duration;
	}

	getDuration(){
		return this.dataService.get("duration", 'monthly');
	}


	// Store all the children component in the object
	// dom_type is the children component name
	// type is the data type you want to display in the children component
	// Eg : <order-analysis-sales-type> - dom_type will be sales_type
	//		in <order-analysis-sales-type>, there might be order channel, order type, sales type
	//		so if order channel, TYPE will be  order_channel
	//		so if order type, TYPE will be  order_type
	constructOverallStructure(dom_type,type,result,refresh){
		var overall_structure = {};

		overall_structure[dom_type] = {};
		overall_structure[dom_type][type] = {};
		overall_structure[dom_type][type] ["data"] = result['data'];
		overall_structure[dom_type][type] ["options"] = result['options'];
		overall_structure[dom_type]["refresh"] = refresh;

	}
	// END

	// Process Every Data into BarChart Structure
	processIntoStandardChartStructure(data,opts){
		// data,type,number_type,loop_level,options_name,show_legend,zoom_end,is_rotate
		var main_options = [],
		keys = [],
		loop_data = {},
		main_data = [],
		result = {},
		main_options_data = {},
		get_data = [];
		// var data = this.data[type];
		if(opts['loop_level'] == 2){
			_.map(data,(value,key) => {          	
				var data_id = [],
				main_options_value = [];
				_.map(value[opts['loop_field']],(data_value,data_key) => {
					loop_data = {};
					// value[data_value.id] = data_value.sales;
					if(opts.hasOwnProperty('use_name')){
						if(opts.use_name == true){

							loop_data[data_value.name.toLowerCase()] = data_value[opts['number_type']];
						}
					}else{
						if(data_value.hasOwnProperty("id")){ 
							loop_data[data_value.id] = data_value[opts['number_type']];
						}else{
							loop_data[data_value.name.toLowerCase()] = data_value[opts['number_type']];
						}
					}

					loop_data['name'] = data_value.name.toUpperCase();
					loop_data['keys'] = opts['type'];
					loop_data['is_chart_click'] = true;
					loop_data['chart_type'] = "standard_chart";
					loop_data['duration_type'] = key;
					loop_data['date'] = value.date;
					loop_data[opts['number_type']] = data_value[opts['number_type']];
					// loop_data[opts['number_type']] = 100;
					main_data.push(loop_data);
				})
			});
		}
		if(opts['loop_level'] == 1){         	
			_.map(data,(value,key) => {          	
				loop_data = {};        
				loop_data['name'] = value.name;
				// loop_data['keys'] = opts['keys'];
				// loop_data['is_chart_click'] = true;
				// loop_data['chart_type'] = "standard_chart";
				loop_data[opts['number_type']] = value[opts['number_type']];
				if(value.hasOwnProperty("percent")){
					loop_data['percentage'] = value.percent;
				}

				main_data.push(loop_data);
			});

		}  

		// Form the options for the graph
		main_options_data['name'] = opts['options_name'];
		main_options_data['attribute'] = opts['number_type'];
		main_options.push(main_options_data);

		main_data = this.sortData(opts, main_data);
		opts['main_options'] = main_options;
		
		var options = this.formOptionsStructure(opts);
		// END
		result['data'] = main_data;
		result['options'] = options;
		return result;
	}
	//END

	// Process Every Data into StackedBarChart Structure
	processIntoStackedBarChartStructure(data,opts){

		// data,type,number_type,loop_level,show_legend,zoom_end,is_rotate
		var main_options = [],
		main_data = [],
		keys = [],
		main_options_data = {},
		result = {},
		new_data = {},
		get_data = [];
				// var data = this.data[type];
		if(opts['loop_level'] == 2){
			_.map(data,(value,key) => {
				var data_id = [],
				main_options_value = [];
				if(_.isEmpty(get_data)){
					get_data = value[opts['loop_field']];    
				}
				new_data = {};
				if(!_.isEmpty(value)){

					new_data['date'] = value.date;
					new_data['id'] = key;
					new_data['name'] = value.name;
					new_data['duration_type'] = key;
					_.map(value[opts['loop_field']],(data_value,data_key) => {

						if(data_value.hasOwnProperty("id")){ 
							new_data[data_value.id] = data_value[opts.number_type];
						}else{
							new_data[data_value.name.toLowerCase()] = data_value[opts.number_type];
						}
					});	
					main_data.push(new_data);		
				}     
				
			});	

			_.map(get_data,(data_values,key) => {
				main_options_data = {};
				// keys.push(data_values.id);
				if(data_values.hasOwnProperty("id")){
					keys.push(data_values.id);
				}else{
					keys.push(data_values.name.toLowerCase());
				}        

				main_options_data['name'] = data_values.name.toUpperCase();
				main_options_data['attribute'] = data_values.id;
				main_options_data['name_as_legend'] = true;

				if(opts.hasOwnProperty("name_as_legend")){
					main_options_data['name_as_legend'] = opts["name_as_legend"];
				}

				main_options.push(main_options_data);
			}); 
		}

		opts['main_options'] = main_options;
		opts['keys'] = keys;

		var options = this.formOptionsStructure(opts);

		main_data = this.sortData(opts, main_data);

		result['data'] = main_data;
		result['options'] = options;

		return result;


	}
	//END
	sortData(opts, main_data){
		var sort_by = _.get(opts, 'sort_by.'+this.selected_duration);
		if(sort_by){
			var new_main_data = _.map(sort_by, (x)=>{
				return _.find(main_data, (y)=>{
					return _.get(y, 'name', '').toLowerCase() == x.toLowerCase();
				});
			});

			if(!_.isEmpty(_.filter(new_main_data, null))){
				main_data = new_main_data;
			}
		} 
		return main_data;
	}
	formOptionsStructure(form_options){
		// type,main_options,keys,show_legend,is_zoom,zoom_end,is_rotate
		var options = {
			'label':{
				'chart_label':{} // for column simple chart label
			}
		};
		options['fields'] = {};
		options['legend'] = {};
		options['fields']['attribute'] = "name";
		options['fields']['main'] = form_options['main_options'];
		options['keys'] = form_options['keys'];
		options['legend']['show'] = form_options['show_legend'];
		options['chart_type'] = form_options['chart_type'];
		options['filter_keys'] = form_options['filter_keys'];
		options['is_chart_click'] = form_options['is_chart_click'];	
		if(_.has(form_options,'is_chart_click')){
			if(form_options.is_chart_click == false){
				options['onClick'] = null;
			}
		}

		options['rotate_text'] = form_options['is_rotate'];
		options['label']['chart_label']['show'] = false;
		options['label']['chart_label']['text'] = "";

		if(form_options['is_zoom'] == true){
			options['xIndexZoom'] = {'start':0, 'end': form_options['zoom_end']};
			options['scrollbar'] = {
				'x': {}
			};
			options['scrollbar']['x']['show'] = true;
		}

		if(_.has(form_options,'show_title')){
			options['label']['chart_label']['show'] = form_options['show_title'];
		}
		if(_.has(form_options,'title')){
			options['label']['chart_label']['text'] = form_options['title'];
		}

		if(_.has(form_options,"custom_callback")){
			options['onClick'] = form_options['custom_callback'];
		}

		if(_.has(form_options,"preload_message")){
			options['preload_message'] = form_options['preload_message'];
		}

		if(_.has(form_options,"position")){
			options['position'] = form_options['position'];
		}

		if(_.has(form_options,"name_as_legend")){
			options['name_as_legend'] = form_options['name_as_legend'];
		}

		return options;
	}


	//Get Start & End Date
	getStartEndDate(data){
		var valueY = data.valueY,

		start_date = '',
		end_date = '';

		var date    = new Date(data.date);
		var selected_type = this.getDuration();

		// var date = new Date("2019-06-01");
		var first_day = new Date(date.getFullYear(), date.getMonth(), 1);
		var last_day = new Date(date.getFullYear(), date.getMonth() + 1, 0);

		var year_month = last_day.getFullYear() + '-'+ (this.processMonthDate(last_day.getMonth() + 1))+ '-' ;

		if(selected_type =='weekly' || selected_type == 'monthly'){
			if(selected_type == 'weekly'){
				end_date =  year_month+(this.processMonthDate(last_day.getDate()));
				start_date  = year_month+(this.processMonthDate(first_day.getDate()));
				if(data.keys != 'combo_name'){
					if(data.hasOwnProperty("id")){
						var get_id = data.duration_type.split('_');
						start_date = year_month+(this.processMonthDate(this.week_date[get_id[1]].start_date));
						end_date =  year_month+(this.processMonthDate(this.week_date[get_id[1]].end_date));
						if(get_id[1] == 5){
							end_date = year_month+(this.processMonthDate(last_day.getDate()));
						}
					}
				}

				if(data.keys == 'combo_name'){ 
					start_date = this.api_filter['start_date'];
					end_date = this.api_filter['end_date'];
				}



			}

			if(selected_type == 'monthly'){ 
				end_date =  year_month+(this.processMonthDate(last_day.getDate()));
				start_date  = year_month+(this.processMonthDate(first_day.getDate()));
			}	
		}

		if(selected_type == 'daily'){
			start_date = data.date;
			end_date =  data.date;
		}

		var result = {};

		result['start_date'] = start_date;
		result['end_date'] = end_date;

		return result;
	}
	//END


	// Add 0 in front of date is date is less than 10
	processMonthDate(date){
		var convert_date;

		convert_date = date < 10 ? '0' + date : date;

		return convert_date;

	}
	// END


	getApiFilter(){
		return this.api_filter;
	}

	getApiData(api,api_filter,callback=null){
		// this.http
		// 	.post(`${environment.server.apiUrl}/`+api,api_filter)
		// 	.toPromise()
		// 	.then(res => {
		// 		if(callback){
		// 			callback(res.json().response);
		// 		}
		// 		this.data = res.json().response;
		// 	})
		// 	.catch(res => {
		// 		console.log('Error:',res);
		// 	})

		this.http
          .get(`${environment.server.apiUrl}/`+api, {
	    	search: this.httpHelper.buildQuery({
            	'key': api_filter.key,
				'q' : api_filter.q
				// 'limit':api_filter.limit,
			})
	    })
          .toPromise()
          .then(res => {
           		if(callback){
					callback(res.json().response);
				}
				this.data = res.json().response;
          })
          .catch(res => {
            
          });
	}
	subscribe(callback){
		var subscription = this.subject.subscribe(callback);
		if(!_.isEmpty(this._data)){
			callback(this._data);
		}

		return subscription;
	}
	ngOnDestroy() {
		_.map(this.subscriptions, (x: any) => { x.unsubscribe(); });
	}
}
