import { Injectable } from '@angular/core';
import { Request, XHRBackend, RequestOptions, Response, Http, RequestOptionsArgs, Headers } from '@angular/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { TOKEN_NAME  } from './auth.service';
import { Router, ActivatedRoute, Params,NavigationExtras } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import * as _ from 'lodash'; 
const AUTH_HEADER_KEY = 'Authorization';
const AUTH_PREFIX = 'Bearer';

@Injectable()
export class ExtendedHttpService extends Http {
  arrParam : any = {};
  constructor(backend: XHRBackend, defaultOptions: RequestOptions, private router: Router,private activatedRoute: ActivatedRoute) {
    super(backend, defaultOptions);
  }

  request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
    //do whatever 
    if (typeof url === 'string') {
      if (!options) {
        options = { headers: new Headers() };
      }
      this.setHeaders(options);
    } else {
      this.setHeaders(url);
    }

    return super.request(url, options).catch(this.catchErrors());
  }

  private catchErrors() {
    return (res: Response) => {
      if (res.status === 401 || res.status === 403) {
        this.activatedRoute.queryParams.subscribe(params => {
          this.arrParam = params;
        });
        // this.arrParam['redirect_url'] = "";
        Object.assign({redirect_url: this.router.url}, this.arrParam);
        // this.arrParam['redirect_url'] = this.router.url;

        //handle authorization errors
        localStorage.removeItem(TOKEN_NAME);
        this.router.navigate(['/auth/login'],{
            queryParams: this.arrParam,
            queryParamsHandling: 'merge',

        });
      }
      return Observable.throw(res);
    };
  }

  private setHeaders(objectToSetHeadersTo: Request | RequestOptionsArgs) {
    //add whatever header that you need to every request
    // objectToSetHeadersTo.headers.set('Access-Control-Expose-Headers', `Authorization`);
    let token = localStorage.getItem(TOKEN_NAME);
    if(objectToSetHeadersTo.headers == null){
        objectToSetHeadersTo.headers.set('Content-Type', `application/json`);
    }
    if(token) {
      // objectToSetHeadersTo.headers.set('Access-Control-Expose-Headers', `Authorization`);
      objectToSetHeadersTo.headers.set(AUTH_HEADER_KEY, `${AUTH_PREFIX} ${token}`);
    }

    
    //objectToSetHeadersTo.headers.set('Token', this.authService.getToken());
  }
}
