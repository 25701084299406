import { Component, Input, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteParams } from 'model/route-params';

declare var $: any;

@Component({
	selector: 'app-layout-user',
	templateUrl: './layout-user.component.html',
	styleUrls: ['./layout-user.component.scss']
})
export class LayoutUserComponent implements OnInit {
	public tutorial: boolean = false;
	subscriptions: any = [];
	show: boolean = false;
	constructor(
		private http: Http,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public routeParams: RouteParams,
		translate: TranslateService
	) {
	}
	ngOnInit() {
		this.subscriptions.push(this.routeParams.subscribe(params => {
			this.show = false;
			if (params.url[0] == "stock" ||
				params.url[0] == "issue-lists" ||
				params.url[0] == "receive-lists" || 
				params.url[0] == "procurement" || 
				params.url[0] == "budget" || 
				params.url[0] == "patient-visit" || 
				params.url[0] == "medication-order" || 
				params.url[0] == "condemn" || 
				params.url[0] == "prescription" || 
				params.url[0] == "counselling" || 
				params.url[0] == "prescription-vas" || 
				params.url[0] == "sam-customer-charter" || 
				params.url[0] == "sam-top" || 
				params.url[0] == "sam-application" || 
				params.url[0] == "inventory-module" || 
				params.url[0] == "intervention-outpatient" || 
				params.url[0] == "intervention-inpatient" || 
				params.url[0] == "ward-pharmacy" || 
				params.url[0] == "mtac" ||
				params.url[0] == "medicine-cost-prescription"
				) {
				if (params.url[params.url.length - 1] == "search" || params.url[params.url.length - 1] == "analysis") {
					this.show = true;
				}
			} else {
				this.show = true;
				if (params.url[0] == "data-sync-status") {
					// console.log("params length:",params.url.length);
					if (params.url.length > 2) {
						this.show = false;
					}
				}

			}
		}));
		// this.checkServerMaintenanceMessage();
	}

	ngAfterViewInit() {
		// this.pageModel.load();
	}

	checkServerMaintenanceMessage() {
		return this.http
			.get(environment.server.apiUrl + '/server/maintenance')
			.toPromise()
			.then(res => {
				var message = res.json().meta.message;
				if (message) {
					$.toast({
						heading: 'Server maintenance',
						text: message,
						position: 'top-center',
						bgColor: '#ffb22b',
						icon: 'warning',
						hideAfter: false,
						stack: 5
					});
				}
				// else{
				//     if(this.pageModel.accessToken.facebook == null && this.pageModel.accessToken.instagram == null){
				//         this.tutorial = true;
				//         $('html, body').css('overflow', 'hidden'); 
				//     }
				// }

			})
			.catch(res => {
				console.log('check maintenance error:', typeof res.json === 'function' ? res.json().meta.message : res.message);
			});
	}
}
