import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'shared-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['../dialog.common.scss', './alert-dialog.component.scss']
})
export class AlertDialogComponent{
	constructor(
		public translate:TranslateService,
		public dialogRef: MatDialogRef<AlertDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
		) {
		console.log('data:', data);
	}
	onCloseClick(): void {
		this.dialogRef.close();
	}
}
